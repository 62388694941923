import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import AddFridge from './AddFridge';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { validateAddFridgeForm } from '../../validators/formValidators';
import * as fridgesActions from '../../../redux/modules/fridges/fridgesActions';
import { showNotification } from '../../helpers/notificationHelper';
import notificationTypes from '../../helpers/notificationTypes';
import i18n from 'i18next';

const selector = formValueSelector('addFridgeForm');

const mapStateToProps = (state) => ({
  name: selector(state, 'name'),
  address: selector(state, 'address'),
  description: selector(state, 'description'),
  pricingCoefficient: selector(state, 'pricingCoefficient'),
  hasLock: selector(state, 'hasLock'),
  externalId: selector(state, 'externalId'),
  latitude: selector(state, 'latitude'),
  longitude: selector(state, 'longitude'),
  isLoading: state.fridges.isLoading,
  isHidden: selector(state, 'isHidden'),
});

const mapDispatchToProps = {
  addFridge: fridgesActions.addFridge,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'addFridgeForm',
    validate: validateAddFridgeForm,
    onSubmit: (payload, dispatch, props) => {
      const location = {
        latitude: payload.latitude ? Number(payload.latitude) : null,
        longitude: payload.longitude ? Number(payload.longitude) : null,
      };
      props
        .addFridge(
          Number(payload.number),
          payload.name,
          payload.address,
          payload.description,
          Number(payload.pricingCoefficient),
          payload.externalId,
          payload.hasLock,
          payload.controllerDeviceAddress,
          payload.lockingDeviceNumber,
          location,
          payload.isHidden,
        )
        .then(() => props.history.push('/fridges'))
        .catch((error) => {
          showNotification(notificationTypes.error, error, i18n.t('Failed to add frdige.'));
        });
    },
  }),
)(AddFridge);
