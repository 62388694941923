import React from 'react';
import { InputBase } from '@material-ui/core';

const InnerPlainInputField = ({ input, label, required, ...custom }) => {
  let styles = {};

  styles.fontSize = 14;
  const innerProps = { style: styles };

  return (
    <React.Fragment>
      <InputBase inputProps={innerProps} required={required} {...input} {...custom} />
    </React.Fragment>
  );
};

export default InnerPlainInputField;
