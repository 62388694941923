import { connect } from 'react-redux';
import * as productsActions from './../../../redux/modules/products/productsActions';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { getPageNumber, getSearchString } from '../../helpers/queryHelper';
import ProductCategoriesList from './ProductCategoriesList';

const mapStateToProps = (state, props) => {
  return {
    productCategories: state.products.detailedCategories,
    isLoading: state.products.isLoading,
    productCategoriesCount: state.products.categoriesCount,
    currentPage: getPageNumber(props),
    searchString: getSearchString(props),
  };
};

const mapDispatchToProps = {
  getProductCategories: productsActions.getDetailedProductCategories,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProductCategoriesList);
