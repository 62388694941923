import React from 'react';
import { TableRow, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Pagination from '@material-ui/lab/Pagination';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import paginationConstants from '../../helpers/paginationConstants';
import commonStyles from '../../helpers/commonStyles';
import { toLongDateTime } from '../../helpers/dateFormatter';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  table: {
    minWidth: '1300px',
  },
  headerRow: {
    backgroundColor: theme.palette.whiteblue,
  },
  tableContent: {
    backgroundColor: theme.palette.white,
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableHeader: {
    borderTop: commonStyles.tableHeaderTopBorder,
  },
});

const ProductsRatingTable = (props) => {
  const { classes, products, handlePageChange, activePage, productsCount } = props;
  const { t } = useTranslation();
  const pagesCount = Math.ceil(productsCount / paginationConstants.itemsPerPage);

  const renderProductsRatingTable = () => {
    if (products.length !== 0) {
      return products.map((product) => (
        <TableRow key={product.id} className={classes.tableContent}>
          <TableCell component="th" scope="row">
            {product.barcodeValue}
          </TableCell>
          <TableCell align="right">{product.name}</TableCell>
          <TableCell align="right">{product.numberOfPurchases}</TableCell>
          <TableCell align="right">{toLongDateTime(product.lastPurchase)}</TableCell>
        </TableRow>
      ));
    }
  };

  return (
    <React.Fragment>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell className={classes.tableHeader}>{t('Barcode')}</TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Name')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Number of orders')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t("Last order's date")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderProductsRatingTable()}</TableBody>
      </Table>
      <Pagination
        className={classes.paginationContainer}
        size={'large'}
        variant="outlined"
        shape="rounded"
        count={pagesCount}
        page={activePage}
        onChange={handlePageChange}
        color="primary"
      />
    </React.Fragment>
  );
};

export default compose(withRouter, withStyles(styles))(ProductsRatingTable);
