export const SummaryBlockStyles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '15%',
    backgroundColor: theme.palette.white,
    marginRight: 'auto',
    marginLeft: '38px',
    marginTop: '20px',
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
    minWidth: '260px',
  },
  container: {
    margin: '10px',
  },
  summaryText: {
    color: theme.palette.gray,
    fontSize: '0.875rem',
  },
});
