import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from '@material-ui/core';
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const GreenCheckbox = withStyles((theme) => ({
  root: {
    '&:$checked': {
      color: theme.palette.primary.dark,
    },
    color: theme.palette.primary.dark,
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);

const InnerCheckboxField = ({ input, label, className }) => (
  <div>
    <FormControlLabel
      control={<GreenCheckbox checked={!!input.value} onChange={input.onChange} />}
      labelPlacement="start"
      label={label}
      className={className}
    />
  </div>
);

export default InnerCheckboxField;
