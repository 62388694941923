import LOGIN_CONSTANTS from './loginConstants';

const defaultState = {
  isLoading: false,
  accessToken: '',
  currentUser: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_CONSTANTS.LOGIN_PENDING:
      return handleRequestPending(state, action.payload);

    case LOGIN_CONSTANTS.LOGIN_SUCCEEDED:
      return handleLoginUser(state, action.payload.accessToken, action.payload.currentUser);

    default:
      return state;
  }
};

function handleRequestPending(state, pending) {
  return {
    ...state,
    isLoading: pending,
  };
}

function handleLoginUser(state, accessToken, currentUser) {
  return {
    ...state,
    accessToken: accessToken,
    currentUser: currentUser,
    isLoading: false,
  };
}
