import { connect } from 'react-redux';
import * as usersActions from './../../../redux/modules/users/usersActions';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { getArrayParam, getDateParam, getPageNumber, getSearchString, getSortOrder } from '../../helpers/queryHelper';
import UsersList from './UsersList';

const mapStateToProps = (state, props) => {
  return {
    users: state.users.users,
    isLoading: state.users.isLoading,
    usersCount: state.users.usersCount,
    currentPage: getPageNumber(props),
    searchString: getSearchString(props),
    sortOrder: getSortOrder(props),
    selectedFridgesIds: getArrayParam(props, 'fridges'),
    endDate: getDateParam(props, 'endDate'),
    startDate: getDateParam(props, 'startDate'),
  };
};

const mapDispatchToProps = {
  getUsers: usersActions.getUsers,
  banUser: usersActions.banUser,
  unbanUser: usersActions.unbanUser,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(UsersList);
