import axios from 'axios';
import { showNotification } from '../components/helpers/notificationHelper';
import notificationTypes from '../components/helpers/notificationTypes';
import i18n from '../i18n';

export const getImageUrl = (imageData) => {
  return axios
    .post('/products/image', imageData)
    .then((response) => response)
    .catch((response) => {
      if (response.response && response.response.status === 415) {
        showNotification(
          notificationTypes.warning,
          i18n.t('Please select an image in one of the following formats: .jpg, .jpeg, .png'),
          i18n.t('Unsupported file format'),
        );
      } else {
        showNotification(
          notificationTypes.error,
          i18n.t('An error occurred while uploading an image. Make sure that image size is less than 3MB.'),
          i18n.t('Image was not uploaded'),
        );
      }

      return response;
    });
};
