import React, { useEffect } from 'react';
import Spinner from '../../common/ProgressIndicators/Spinner';
import AddUpdateFridgeForm from '../../common/Forms/AddUpdateFridgeForm';
import { withTranslation } from 'react-i18next';

const UpdateFridge = (props) => {
  const { getFridgeById, fridgeId, history, isLoading, selectedFridge, t } = props;

  useEffect(() => {
    getFridgeById(fridgeId, history);
  }, [getFridgeById, fridgeId, history]);

  return isLoading ? (
    <Spinner />
  ) : (
    <AddUpdateFridgeForm pageTitle={`${t('Fridge editing')} #${selectedFridge.number}`} {...props} />
  );
};

export default withTranslation()(UpdateFridge);
