import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import Spinner from '../../common/ProgressIndicators/Spinner';
import paginationConstants from '../../helpers/paginationConstants';
import HealthChecksTable from './HealthChecksTable';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '96%',
    minWidth: 700,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '20px',
    marginBottom: '10px',
    backgroundColor: theme.palette.white,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
  },
  gridItem: {
    margin: '10px',
  },
  title: {
    color: theme.palette.primary.dark,
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
    backgroundColor: theme.palette.white,
  },
});

const HealthChecks = (props) => {
  const {
    classes,
    fridge,
    healthChecks,
    isLoading,
    healthChecksCount,
    currentPage,
    getFridgeById,
    fridgeId,
    getFridgeHealthChecks,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    getFridgeById(fridgeId, props.history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getFridgeHealthChecks(fridgeId, currentPage, paginationConstants.itemsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handlePageChange = (event, pageNumber) => {
    const queryString = `page=${pageNumber}`;
    props.history.push({
      pathname: props.history.location.pathname,
      search: queryString,
    });
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={classes.root}>
          <Grid container direction="row" spacing={0} className={classes.container}>
            <Grid item className={classes.gridItem}>
              <Typography className={classes.title} variant={'h6'}>
                {t('Fridge health checks')} #{fridge.number}; {fridge.name}; {fridge.address}.
              </Typography>
            </Grid>
          </Grid>
          <HealthChecksTable
            activePage={currentPage}
            handlePageChange={handlePageChange}
            healthChecks={healthChecks}
            healthChecksCount={healthChecksCount}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default compose(withStyles(styles), withRouter)(HealthChecks);
