import React from 'react';
import Select from 'react-select';

const InnerSelectField = ({ input, label, meta: { touched, error }, required, options, ...custom }) => {
  return (
    <React.Fragment>
      <Select
        value={input.value}
        required={required}
        options={options}
        clearable={custom.clearable}
        backspaceRemoves={custom.backspaceRemoves}
        searchable={custom.searchable}
        deleteRemoves={custom.deleteRemoves}
        onChange={(value) => input.onChange(value)}
        {...custom}
      />
      {touched && error && (
        <span style={{ color: 'red' }} {...custom}>
          {error}
        </span>
      )}
    </React.Fragment>
  );
};

export default InnerSelectField;
