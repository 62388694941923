import { connect } from 'react-redux';
import * as ordersActions from './../../../redux/modules/orders/ordersActions';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { getArrayParam, getDateParam, getPageNumber } from '../../helpers/queryHelper';
import OrdersHistoryList from './OrdersHistoryList';
import { getQueryParamFromUrl } from '../../helpers/getParamsFromUrl';

const mapStateToProps = (state, props) => {
  return {
    orders: state.orders.orders,
    isLoading: state.orders.isLoading,
    excelReportLoading: state.orders.excelReportLoading,
    excelReportIntervalIsValid: state.orders.excelReportIntervalIsValid,
    ordersCount: state.orders.ordersCount,
    currentPage: getPageNumber(props),
    fridgesIds: getArrayParam(props, 'fridges'),
    endDate: getDateParam(props, 'endDate'),
    startDate: getDateParam(props, 'startDate'),
    orderStatus: getQueryParamFromUrl(props, 'orderStatus'),
    userId: getQueryParamFromUrl(props, 'userId'),
  };
};

const mapDispatchToProps = {
  getOrders: ordersActions.getOrders,
  getOrdersExcelReport: ordersActions.getOrdersExcelReport,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(OrdersHistoryList);
