import React, { useState } from 'react';
import { TableRow, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import ConfirmationModal from '../../Modals/ConfirmationModal/ConfirmationModal';
import commonStyles from '../../helpers/commonStyles';
import FridgeQrCode from './FridgeQrCode';
import Menu from '../../fields/Menu';
import paginationConstants from '../../helpers/paginationConstants';
import Pagination from '@material-ui/lab/Pagination';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  okStatusButton: {
    color: theme.palette.success.main,
  },
  failedStatusButton: {
    color: theme.palette.error.dark,
  },
  table: {
    minWidth: 700,
  },
  headerRow: {
    backgroundColor: theme.palette.whiteblue,
  },
  tableContent: {
    backgroundColor: theme.palette.white,
  },
  tableHeader: {
    borderTop: commonStyles.tableHeaderTopBorder,
  },
  lockDeviceActionsFormContainer: {
    minWidth: '90px',
    width: '100px',
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const FridgesTable = (props) => {
  const { history, classes, fridges, handlePageChange, activePage, fridgesCount } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFridgeId, setSelectedFridgeId] = useState(null);
  const [selectedFridgeNumber, setSelectedFridgeNumber] = useState(null);
  const pagesCount = Math.ceil(fridgesCount / paginationConstants.itemsPerPage);
  const { t } = useTranslation();

  const handleOpenModal = (id, number) => {
    setIsModalOpen(true);
    setSelectedFridgeId(id);
    setSelectedFridgeNumber(number);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFridgeId(null);
  };

  const handleSubmitModal = () => {
    props.deleteFridge(selectedFridgeId);
    handleCloseModal();
  };

  const editFridge = (fridgeId) => {
    history.push(`/fridges/${fridgeId}/edit`);
  };

  const openUnlocksHistory = (fridgeId) => {
    history.push(`/fridges/unlocks?fridgeId=${fridgeId}`);
  };

  const openHealthChecks = (fridgeId) => {
    history.push(`/fridges/${fridgeId}/healthChecks`);
  };

  const openProductsPage = (fridgeId) => {
    history.push(`/products?fridgeId=${fridgeId}`);
  };

  const renderTableRow = (fridge) => {
    return (
      <TableRow key={fridge.number} className={classes.tableContent}>
        <TableCell component="th" scope="row">
          {fridge.number}
        </TableCell>
        <TableCell align="right">{fridge.name}</TableCell>
        <TableCell align="right">{fridge.address}</TableCell>
        <TableCell align="right">{fridge.description}</TableCell>
        <TableCell align="right">{fridge.pricingCoefficient}</TableCell>
        <TableCell align="right">
          <FridgeQrCode fridge={fridge} />
        </TableCell>
        {fridge.hasLock ? (
          <React.Fragment>
            <TableCell
              align="right"
              className={
                fridge.healthStatus &&
                (fridge.healthStatus === 'ОК' ? classes.okStatusButton : classes.failedStatusButton)
              }
            >
              {!fridge.healthStatus ? '—' : fridge.healthStatus}
            </TableCell>
            <TableCell className={classes.lockDeviceActionsFormContainer}>
              <Menu
                withArrow
                options={[
                  {
                    key: 'unlocks',
                    label: t('Unlocks'),
                    handleClick: () => openUnlocksHistory(fridge.id),
                  },
                  {
                    key: 'healthChecks',
                    label: t('Health checks'),
                    handleClick: () => openHealthChecks(fridge.id),
                  },
                ]}
              >
                {t('Lock')}
              </Menu>
            </TableCell>
          </React.Fragment>
        ) : (
          <TableCell colSpan={2} align="center">
            {t('No lock')}
          </TableCell>
        )}
        <TableCell align="center">
          <Menu
            withArrow
            options={[
              {
                key: 'edit',
                label: t('Edit'),
                handleClick: () => editFridge(fridge.id),
              },
              {
                key: 'delete',
                label: t('Delete'),
                handleClick: () => handleOpenModal(fridge.id, fridge.number),
              },
              {
                key: 'showProducts',
                label: t('Show products'),
                handleClick: () => openProductsPage(fridge.id),
              },
            ]}
          >
            {t('Action')}
          </Menu>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell className={classes.tableHeader}>#</TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Name')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Address')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Description')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Pricing coefficient')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Qr-code')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Status')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="center" />
            <TableCell className={classes.tableHeader} align="center" />
          </TableRow>
        </TableHead>
        <TableBody>{fridges.length !== 0 && fridges.map((fridge) => renderTableRow(fridge))}</TableBody>
      </Table>
      <Pagination
        className={classes.paginationContainer}
        size={'large'}
        variant="outlined"
        shape="rounded"
        count={pagesCount}
        page={activePage}
        onChange={handlePageChange}
        color="primary"
      />
      <ConfirmationModal
        isOpen={isModalOpen}
        confirmButtonName={t('Yes')}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitModal}
        modalTitle={`${t('Are you shure you want to delete the fridge')} #${selectedFridgeNumber}?`}
      />
    </React.Fragment>
  );
};

export default compose(withRouter, withStyles(styles))(FridgesTable);
