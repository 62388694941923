import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import AddProductForm from './AddProductForm';
import * as productsActions from './../../../redux/modules/products/productsActions';
import { validateAddUpdateProductForm } from '../../validators/formValidators';
import { showNotification } from '../../helpers/notificationHelper';
import notificationTypes from '../../helpers/notificationTypes';
import i18n from 'i18next';

const selector = formValueSelector('addProductForm');

const mapStateToProps = (state) => ({
  name: selector(state, 'name'),
  barcodeValue: selector(state, 'barcodeValue'),
  imageUrl: selector(state, 'imageUrl'),
  price: selector(state, 'price'),
  proteins: selector(state, 'proteins'),
  fats: selector(state, 'fats'),
  carbohydrates: selector(state, 'carbohydrates'),
  kiloCalories: selector(state, 'kiloCalories'),
  ingredients: selector(state, 'ingredients'),
  category: selector(state, 'category'),
  categories: state.products.productCategories,
  isLoading: state.products.isLoading,
});

const mapDispatchToProps = {
  addProduct: productsActions.addProduct,
  getProductCategories: productsActions.getProductCategories,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'addProductForm',
    validate: validateAddUpdateProductForm,
    onSubmit: (payload, dispatch, props) => {
      props
        .addProduct(payload)
        .then(() => {
          props.history.push('/products');
        })
        .catch((error) => {
          showNotification(notificationTypes.error, error, i18n.t('Fialed to add product.'));
        });
    },
  }),
)(AddProductForm);
