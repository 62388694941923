import React from 'react';

import { default as MUITableCell } from '@material-ui/core/TableCell';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SortIcon from '@material-ui/icons/Sort';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
  contentWraper: {
    display: 'inline-flex',
  },
});

const sortIcons = {
  false: <SortIcon />,
  asc: <ArrowDropUpIcon />,
  desc: <ArrowDropDownIcon />,
};

const TableCell = (props) => {
  const { classes, isSortable, name, currentSort, onChangeSort, ...other } = props;
  let SortIconComponent;

  if (isSortable) {
    const sortDirection = currentSort.name === name ? currentSort.direction : false;
    SortIconComponent = sortIcons[sortDirection];
  }

  return (
    <MUITableCell onClick={() => (isSortable ? onChangeSort(name) : null)} {...other}>
      <div className={classes.contentWraper} style={{ cursor: isSortable && 'pointer' }}>
        {SortIconComponent}
        {props.children}
      </div>
    </MUITableCell>
  );
};

export default withStyles(styles)(TableCell);
