import { globalThemeObject } from '../styles/GlobalThemeObject';

export default {
  tableHeaderTopBorder: '1px solid rgba(224, 224, 224, 1)',
  selectComponentStyles: {
    control: (base) => ({
      ...base,
      maxHeight: '36px',
      minHeight: '36px',
      maxWidth: '270px',
      minWidth: '270px',
      overflow: 'hidden',
      border: 0,
      boxShadow: 'none',
      fontSize: '16px',
      fontFamily: globalThemeObject.typography.fontFamily,
      cursor: 'pointer',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: 'rgb(195, 195, 195)',
      fontSize: '16px',
      fontFamily: globalThemeObject.typography.fontFamily,
    }),
    menu: (defaultStyles) => ({
      ...defaultStyles,
      color: globalThemeObject.palette.black,
      fontSize: '16px',
      cursor: 'pointer',
      fontFamily: globalThemeObject.typography.fontFamily,
      marginTop: '10px',
      overflow: 'none',
      zIndex: '10',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? globalThemeObject.palette.primary.dark
          : isFocused
          ? globalThemeObject.palette.whitegrey
          : null,
        color: isDisabled
          ? globalThemeObject.palette.black
          : isSelected
          ? globalThemeObject.palette.white
          : isFocused && null,

        cursor: isDisabled ? 'not-allowed' : 'pointer',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && isSelected && globalThemeObject.palette.primary.dark,
        },
        width: 'auto',
      };
    },
    indicatorsContainer: (defaultStyles) => ({
      ...defaultStyles,
      overflow: 'hidden',
      maxHeight: '36px',
    }),
    valueContainer: (defaultStyles) => ({
      ...defaultStyles,
      maxHeight: '36px',
      textOverflow: 'ellipsis',
      maxWidth: '90%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'initial',
      fontSize: '16px',
      color: globalThemeObject.select.lightblack,
    }),
    input: (defaultStyles) => ({
      ...defaultStyles,
      border: 'none',
      overflow: 'auto',
      fontSize: '16px',
      fontWeight: 400,
      resize: 'none',
      width: '100%',
      '& input': {
        fontFamily: 'inherit',
      },
    }),
  },
};
