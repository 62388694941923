import React from 'react';
import { withStyles } from '@material-ui/core';
import HeaderBar from '../../common/HeaderBar/HeaderBar';
import ProductCategoriesListContainer from './ProductCategoriesListContainer';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    paddingTop: '58px',
  },
  contentWrapper: {
    display: 'flex',
    minHeight: 'calc(100vh - 58px)',
    width: '100%',
    flexDirection: 'column',
    backgroundColor: theme.palette.whitegrey,
  },
});

class ProductsListPage extends React.Component {
  render() {
    const { classes, t } = this.props;
    return (
      <React.Fragment>
        <div className={classes.root}>
          <HeaderBar pageTitle={t('Categories')} />
          <div className={classes.contentWrapper}>
            <ProductCategoriesListContainer />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), withStyles(styles))(ProductsListPage);
