import React from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import NumberOfLocksSelectField, { customStyles } from '../../fields/Select/NumberOfFridgeLocksSelectField';
import MultilineInputField from '../../fields/Input/MutilineInputField';
import CheckboxField from '../../fields/Checkbox/CheckboxField';
import InputField from '../../fields/Input/InputField';
import LocationInputField from '../../fields/Input/Location/LocationInputField';

const styles = (theme) => ({
  button: {
    fontSize: '18px',
    padding: '4px 16px',
    width: '60%',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
  },
  title: {
    color: theme.palette.primary.dark,
  },
  childElement: {
    width: '100%',
    marginLeft: '50px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  flexContainer: {
    minWidth: '650px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    border: 'none',
    width: '600px',
    minWidth: '350px',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '2% 5% 1% 5%',
    fontFamily: theme.typography.fontFamily,
  },
  formField: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '97%',
  },
  checkboxField: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    backgroundColor: theme.palette.whitegrey,
    '&:hover': {
      backgroundColor: theme.palette.grey.light,
      borderColor: theme.palette.black,
      borderWidth: '1px',
    },
  },
  checkboxText: {
    fontSize: '1rem',
    color: theme.palette.grey.dark,
  },
  selectedCheckboxText: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
});

const AddUpdateFridgeForm = (props) => {
  const { classes, pristine, pageTitle, hasLock } = props;
  const { t } = useTranslation();

  return (
    <form className={classes.flexContainer} onSubmit={props.handleSubmit}>
      <div className={classes.childElement}>
        <Typography className={classes.title} color={'primary'} variant={'h5'}>
          {pageTitle}
        </Typography>
      </div>
      <InputField className={classes.input} name="number" label={t('Fridge number')} variant="outlined" />
      <InputField className={classes.input} name="name" label={t('Fridge name')} variant="outlined" />
      <InputField className={classes.input} name="address" label={t('Fridge address')} variant="outlined" />
      <LocationInputField className={classes.input} style={{ display: 'flex' }} />
      <MultilineInputField
        className={classes.input}
        name="description"
        label={t('Fridge description')}
        variant="outlined"
      />
      <InputField
        className={classes.input}
        name="pricingCoefficient"
        label={t('Fridge pricing coefficient')}
        variant="outlined"
      />
      <div className={`${classes.input} ${classes.checkboxField}`}>
        <CheckboxField
          label={
            <Typography className={`${classes.checkboxText} ${hasLock && classes.selectedCheckboxText}`}>
              {t('Fridge is hidden')}
            </Typography>
          }
          name="isHidden"
          className={classes.formField}
        />
      </div>
      <div className={`${classes.input} ${classes.checkboxField}`}>
        <CheckboxField
          label={
            <Typography className={`${classes.checkboxText} ${hasLock && classes.selectedCheckboxText}`}>
              {t('Fridge has lock')}
            </Typography>
          }
          name="hasLock"
          className={classes.formField}
        />
      </div>
      {hasLock && (
        <InputField
          className={classes.input}
          name="controllerDeviceAddress"
          label={t('Controller device address')}
          variant="outlined"
        />
      )}
      {hasLock && (
        <NumberOfLocksSelectField
          placeholder={t('Controller device number')}
          className={classes.input}
          name="lockingDeviceNumber"
          styles={customStyles}
        />
      )}
      <div className={classes.childElement}>
        <Button disabled={pristine} variant="contained" type="submit" className={classes.button}>
          {t('Save')}
        </Button>
      </div>
    </form>
  );
};

export default withStyles(styles)(AddUpdateFridgeForm);
