import { connect } from 'react-redux';
import * as ordersActions from './../../../../redux/modules/orders/ordersActions';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { getArrayParam, getDateParam } from '../../../helpers/queryHelper';
import OrdersSummary from './OrdersSummary';
import { getQueryParamFromUrl } from '../../../helpers/getParamsFromUrl';

const mapStateToProps = (state, props) => {
  return {
    totalOrders: state.orders.ordersSummary.totalOrders,
    totalSum: state.orders.ordersSummary.totalSum,
    totalPaidWithBonuses: state.orders.ordersSummary.totalPaidWithBonuses,
    totalPaidWithMoney: state.orders.ordersSummary.totalPaidWithMoney,
    fridgesIds: getArrayParam(props, 'fridges'),
    endDate: getDateParam(props, 'endDate'),
    startDate: getDateParam(props, 'startDate'),
    orderStatus: getQueryParamFromUrl(props, 'orderStatus'),
    userId: getQueryParamFromUrl(props, 'userId'),
  };
};

const mapDispatchToProps = {
  getOrdersSummary: ordersActions.getOrdersSummary,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(OrdersSummary);
