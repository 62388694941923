import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import * as fridgesActions from '../../../../redux/modules/fridges/fridgesActions';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import FilterUnlockHistoryForm from './FilterUnlockHistoryForm';
import { getQueryParamFromUrl } from '../../../helpers/getParamsFromUrl';
import {
  getFridgesUnlocksQueryString,
  getSelectedFridgeUnlocksQueryString,
} from '../../../helpers/Fridges/fridgesUnlocksQueryHelper';

const mapStateToProps = (state, props) => ({
  initialValues: {
    onlySuspiciousUnlocks: getQueryParamFromUrl(props, 'onlySuspiciousUnlocks') === 'true' ? true : false,
    fridge: state.fridges.fridges
      .filter((f) => props.fridgeId === f.id)
      .map((f) => ({ value: f.id, label: `#${f.number}, ${f.name}` }))[0],
  },
});

const mapDispatchToProps = {
  getFridgeUnlockHistory: fridgesActions.getFridgeUnlockHistory,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'filterFridgeUnlocksForm',
    onSubmit: (payload, dispatch, props) => {
      let query;
      if (payload.fridge) {
        query = getSelectedFridgeUnlocksQueryString(1, payload.onlySuspiciousUnlocks, payload.fridge.value);
      } else {
        query = getFridgesUnlocksQueryString(1, payload.onlySuspiciousUnlocks);
      }
      props.history.push({
        pathname: props.history.location.pathname,
        search: query,
      });
    },
    enableReinitialize: true,
  }),
)(FilterUnlockHistoryForm);
