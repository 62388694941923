import React from 'react';
import { Redirect } from 'react-router';

const withAuthorization = (Component) => {
  class RedirectComponent extends React.Component {
    render() {
      if (!localStorage.getItem('currentUser')) {
        return <Redirect to="/login" />;
      }

      document.title = localStorage.getItem('sellerName');

      return <Component {...this.props} />;
    }
  }
  return RedirectComponent;
};
export default withAuthorization;
