import React from 'react';
import QRCode from 'qrcode.react';
import { Link, withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

const styles = (theme) => ({
  downloadLink: {
    color: theme.palette.black,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  qrCode: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
});

class FridgeQrCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qrCodeShouldBeRendered: false,
      qrCodeIsRendered: false,
      qrCode: null,
    };
    this.qrCodeRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.qrCodeShouldBeRendered && this.state.qrCodeShouldBeRendered) {
      const qrCode = this.qrCodeRef;
      this.setState({
        ...this.state,
        qrCode: qrCode,
      });
    }
  }

  renderQrCode = () => {
    this.setState({
      ...this.state,
      qrCodeShouldBeRendered: true,
      qrCodeIsRendered: true,
    });
  };

  downloadFridgeQrCode = () => {
    //first child of div block is canvas with qrCode
    const canvas = this.state.qrCode.current.children[0];

    //convert canvas to png
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');

    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `fridge-n${this.props.fridge.number}_${this.props.fridge.name}_qr-code.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  render() {
    const { classes, fridge, t } = this.props;

    const sellerId = localStorage.getItem('sellerId');

    const qrCodeData = {
      command: 'SELECT_FRIDGE',
      version: 1,
      seller: sellerId,
      data: {
        fridgeId: fridge.id,
      },
    };

    const minifiedQrCodeData = JSON.stringify(qrCodeData);

    return (
      <React.Fragment>
        {this.state.qrCodeShouldBeRendered && (
          <div ref={this.qrCodeRef} className={classes.qrCode}>
            <QRCode id={fridge.id} value={minifiedQrCodeData} size={700} level={'H'} includeMargin={true} />
          </div>
        )}
        <Link
          className={classes.downloadLink}
          onClick={() => {
            !this.state.qrCodeIsRendered && this.renderQrCode();
            this.state.qrCode && this.downloadFridgeQrCode();
          }}
        >
          {t('Download')}
        </Link>
        {this.state.qrCode && this.downloadFridgeQrCode()}
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), withStyles(styles))(FridgeQrCode);
