import React from 'react';
import { Field } from 'redux-form';
import InnerSelectField from './InnerSelectField';
import { globalThemeObject } from '../../styles/GlobalThemeObject';
import commonStyles from '../../helpers/commonStyles';

const NumberOfLocksSelectField = (props) => {
  let items = props.items;
  if (!items) {
    items = [
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
    ];
  }

  return (
    <Field
      name={props.name}
      options={items.map((item) => {
        return { value: item.value, label: item.label };
      })}
      component={InnerSelectField}
      {...props}
    />
  );
};

export default NumberOfLocksSelectField;

export const customStyles = {
  control: (base, state) => ({
    ...base,
    fontSize: '16px',
    fontFamily: globalThemeObject.typography.fontFamily,
    cursor: 'pointer',
    backgroundColor: globalThemeObject.palette.whitegrey,
    '&:hover': { borderColor: globalThemeObject.palette.black },
    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxShadow: 'none',
  }),
  option: commonStyles.selectComponentStyles.option,
  menu: commonStyles.selectComponentStyles.menu,
};
