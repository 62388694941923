import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/es/styles';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import IconButton from '@material-ui/core/IconButton';
import CategoriesMultiSelectField from '../../../fields/Select/CategoriesMultiSelectField';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import PlainInputField from '../../../fields/Input/PlainInputField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Field } from 'redux-form';
import FridgesSelectFieldWithPagination from '../../../fields/SelectWithPagination/FridgesSelectFieldWithPagination';

const styles = (theme) => ({
  input: {
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    fontFamily: theme.typography.fontFamily,
  },
  filterContainer: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    margin: '5px',
  },
  searchButton: {
    minWidth: '40px',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  filterButton: {
    marginLeft: '10px',
  },
  formControlLabel: {
    marginLeft: '10px',
  },
});

const FilterProductsForm = (props) => {
  const { classes, handleSubmit, categories, selectedFridgeId } = props;
  const { t } = useTranslation();
  const [fridgeIsSelected, setFridgeIsSelected] = useState(selectedFridgeId ? true : false);

  const changeSelectedFridge = (fridge) => {
    if (fridge) {
      setFridgeIsSelected(true);
    } else {
      setFridgeIsSelected(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.container}>
        {fridgeIsSelected && (
          <div className={classes.formControlLabel}>
            <Field
              name="showProductsWithCustomPriceOnly"
              label={'Only custom prices'}
              component={({ input, label }) => {
                const isChecked = (input.value ? true : false) && fridgeIsSelected;
                return (
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Switch
                        checked={isChecked}
                        onChange={input.onChange}
                        color="primary"
                        disabled={!fridgeIsSelected}
                      />
                    }
                    label={t(label)}
                  />
                );
              }}
            />
          </div>
        )}
        <div className={classes.filterContainer}>
          <FridgesSelectFieldWithPagination
            name="selectedFridge"
            placeholder={t('Fridge not selected')}
            onChange={(value) => changeSelectedFridge(value)}
          />
        </div>
        <div className={classes.filterContainer}>
          <CategoriesMultiSelectField name="selectedCategories" categories={categories} />
        </div>
        <div className={classes.filterContainer}>
          <IconButton className={classes.searchButton} size={'small'} type="submit">
            <SearchRoundedIcon />
          </IconButton>
          <PlainInputField
            className={classes.input}
            name="title"
            placeholder={t('Search')}
            inputProps={{ 'aria-label': 'naked' }}
          />
        </div>
        <Button className={classes.filterButton} variant={'outlined'} type="submit">
          {t('Apply filters')}
        </Button>
      </div>
    </form>
  );
};

export default withStyles(styles)(FilterProductsForm);
