import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { validateAddUpdateProductCategoryForm } from '../../validators/formValidators';
import * as productsActions from '../../../redux/modules/products/productsActions';
import { getRouteParamFromUrl } from '../../helpers/getParamsFromUrl';
import UpdateProductCategory from './UpdateProductCategory';
import { showNotification } from '../../helpers/notificationHelper';
import notificationTypes from '../../helpers/notificationTypes';
import i18n from 'i18next';

const selector = formValueSelector('updateProductCategoryForm');

const mapStateToProps = (state, ownProps) => ({
  name: selector(state, 'name'),
  isLoading: state.products.isLoading,
  categoryId: getRouteParamFromUrl(ownProps, 'id'),
  selectedCategory: state.products.selectedCategory,
  initialValues: {
    ...state.products.selectedCategory,
  },
});

const mapDispatchToProps = {
  updateProductCategory: productsActions.updateProductCategory,
  getProductCategoryById: productsActions.getCategoryById,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'updateProductCategoryForm',
    validate: validateAddUpdateProductCategoryForm,
    onSubmit: (payload, dispatch, props) => {
      props
        .updateProductCategory(payload, props.categoryId)
        .then(() => props.history.push('/products/categories'))
        .catch((error) => {
          showNotification(notificationTypes.error, error, i18n.t('Changes were not saved.'));
        });
    },
    enableReinitialize: true,
  }),
)(UpdateProductCategory);
