import { getFridges } from '../../../redux/modules/fridges/fridgesSelectors';
import FridgesList from './FridgesList';
import { connect } from 'react-redux';
import * as fridgesActions from './../../../redux/modules/fridges/fridgesActions';
import { getPageNumber } from '../../helpers/queryHelper';
import { withRouter } from 'react-router';
import { compose } from 'redux';

const mapStateToProps = (state, props) => {
  return {
    fridges: getFridges(state),
    isLoading: state.fridges.isLoading,
    fridgesCount: state.fridges.fridgesCount,
    currentPage: getPageNumber(props),
  };
};

const mapDispatchToProps = {
  getFridges: fridgesActions.getFridges,
  deleteFridge: fridgesActions.deleteFridge,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(FridgesList);
