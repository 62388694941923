export const getFridgesUnlocksQueryString = (pageNumber, onlySuspiciousUnlocks) => {
  let queryString = `page=${pageNumber}&onlySuspiciousUnlocks=${onlySuspiciousUnlocks}`;
  return queryString;
};

export const getSelectedFridgeUnlocksQueryString = (pageNumber, onlySuspiciousUnlocks, fridgeId) => {
  let queryString = getFridgesUnlocksQueryString(pageNumber, onlySuspiciousUnlocks);
  if (fridgeId) {
    queryString = `${queryString}&fridgeId=${fridgeId}`;
  }
  return queryString;
};
