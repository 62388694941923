import React from 'react';
import FridgesListContainer from './FridgesListContainer';
import { withStyles } from '@material-ui/core';
import HeaderBar from '../../common/HeaderBar/HeaderBar';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    paddingTop: '58px',
  },
  contentWrapper: {
    display: 'flex',
    minHeight: 'calc(100vh - 58px)',
    width: '100%',
    flexDirection: 'column',
    backgroundColor: theme.palette.whitegrey,
  },
});

const FridgesListPage = (props) => {
  const { classes, t } = props;
  return (
    <React.Fragment>
      <div className={classes.root}>
        <HeaderBar pageTitle={t('Fridges')} />
        <div className={classes.contentWrapper}>
          <FridgesListContainer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default compose(withTranslation(), withStyles(styles))(FridgesListPage);
