import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { SummaryBlockStyles } from '../../../styles/SummaryBlockStyles';
import { useTranslation } from 'react-i18next';

const ProductDefaultPrice = (props) => {
  const { classes, productId, getProductById, product } = props;
  const { t } = useTranslation();

  useEffect(() => {
    getProductById(productId);
  }, [getProductById, productId]);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container direction="row" spacint={0} className={classes.container}>
          <Typography className={classes.summaryText}>
            {t('Default price')}: {product.price}
          </Typography>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default withStyles(SummaryBlockStyles)(ProductDefaultPrice);
