import * as queryString from 'query-string';

export const getQueryParamFromUrl = (props, paramName) => {
  const url = props.location.search;
  return queryString.parse(url)[paramName];
};

export const getRouteParamFromUrl = (props, paramName) => {
  const url = props.match.params;
  return url[paramName];
};
