import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Spinner from '../../common/ProgressIndicators/Spinner';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    width: '96%',
    minWidth: '700px',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '20px',
    marginBottom: '10px',
    backgroundColor: theme.palette.whitegrey,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
  },
  gridItem: {
    margin: '10px',
  },
  importButton: {
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
  },
  importContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
    backgroundColor: theme.palette.white,
  },
});

class Import extends React.Component {
  render() {
    const { classes, isLoading, importImages, importProducts, t } = this.props;
    return (
      <React.Fragment>
        <div className={classes.root}>
          <Grid container direction="row" spacing={0} className={classes.importContainer}>
            <Grid item className={classes.gridItem}>
              <Button variant={'contained'} className={classes.importButton} onClick={() => importProducts()}>
                {t('Import products')}
              </Button>
            </Grid>
            <Grid item className={classes.gridItem}>
              <Button variant={'contained'} className={classes.importButton} onClick={() => importImages()}>
                {t('Import pictures')}
              </Button>
            </Grid>
          </Grid>
          {isLoading && <Spinner />}
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), withStyles(styles))(Import);
