import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Spinner from '../../common/ProgressIndicators/Spinner';
import AddIcon from '@material-ui/icons/Add';

import ProductsTable from './ProductsTable';

import FilterProductsFormContainer from './FilterProducts/FilterProductsFormContainer';
import { getProductsQueryStringWithSorting } from '../../helpers/Products/productsQueryHelper';
import paginationConstants from '../../helpers/paginationConstants';
import XmlFilesUploader from './XmlFilesUploader';
import sellersConstants from '../../helpers/sellersConstants';
import { changeSortOrder, getSortOrderObjectFromString } from '../../helpers/Sort/SortOrderHelper';
import { capitalize } from '../../helpers/stringsHelper';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '96%',
    minWidth: 700,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '20px',
    marginBottom: '10px',
    backgroundColor: theme.palette.white,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
    justifyContent: 'space-between',
  },
  gridItem: {
    margin: '10px',
  },
  actionButton: {
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
    lineHeight: '1.5',
    height: '36px',
    marginLeft: '30px',
  },
  title: {
    color: theme.palette.primary.dark,
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
    backgroundColor: theme.palette.white,
    minWidth: '1300px',
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filtersMargin: {
    marginLeft: '10px',
  },
});

const ProductsList = (props) => {
  const {
    classes,
    products,
    isLoading,
    productsCount,
    currentPage,
    searchString,
    productCategories,
    categoriesIds,
    selectedFridgeId,
    getProductCategories,
    getProducts,
    importProductsFromXml,
    sortOrder,
    t,
    showProductsWithCustomPriceOnly,
  } = props;
  const samberuSellerIsSelected = localStorage.getItem('sellerId') === sellersConstants.samberu;

  const [currentSortOrder, setCurrentSortOrder] = useState({
    name: 'name',
    direction: 'asc',
  });

  useEffect(() => {
    if (sortOrder) {
      const sortOrderObject = getSortOrderObjectFromString(sortOrder);
      setCurrentSortOrder(sortOrderObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrder, setCurrentSortOrder]);

  useEffect(() => {
    getProductCategories();
    getProducts(
      currentPage,
      paginationConstants.itemsPerPage,
      searchString,
      categoriesIds,
      sortOrder,
      selectedFridgeId,
      showProductsWithCustomPriceOnly,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getProductCategories,
    getProducts,
    currentPage,
    searchString,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    categoriesIds.toString(),
    sortOrder,
    selectedFridgeId,
    showProductsWithCustomPriceOnly,
  ]);

  const handlePageChange = (event, pageNumber) => {
    const queryString = getProductsQueryStringWithSorting(
      pageNumber,
      searchString,
      categoriesIds,
      sortOrder,
      selectedFridgeId,
      showProductsWithCustomPriceOnly,
    );

    props.history.push({
      pathname: props.history.location.pathname,
      search: queryString,
    });
  };

  const toggleSortOrder = (sortOrder) => {
    const newSortOrder = changeSortOrder(currentSortOrder, sortOrder);
    const newSortOrderString = newSortOrder.name + capitalize(newSortOrder.direction);

    setCurrentSortOrder(newSortOrder);

    const queryString = getProductsQueryStringWithSorting(
      currentPage,
      searchString,
      categoriesIds,
      newSortOrderString,
      selectedFridgeId,
      showProductsWithCustomPriceOnly,
    );
    props.history.push({
      pathname: props.history.location.pathname,
      search: queryString,
    });
  };

  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={0} className={classes.container}>
        <Grid item className={classes.gridItem}>
          <Typography className={classes.title} variant={'h6'}>
            {t('List of products')}
          </Typography>
        </Grid>
        <Grid item className={`${classes.gridItem} ${classes.searchContainer}`}>
          {samberuSellerIsSelected && <XmlFilesUploader importProductsFromXml={importProductsFromXml} />}
          <Button
            startIcon={<AddIcon />}
            variant={'contained'}
            className={`${classes.actionButton} ${samberuSellerIsSelected && classes.filtersMargin}`}
            onClick={() => props.history.push('/products/add')}
          >
            {t('Add')}
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={0} className={classes.filtersContainer}>
        <FilterProductsFormContainer
          searchString={searchString}
          categoriesIds={categoriesIds}
          categories={productCategories}
          selectedFridgeId={selectedFridgeId}
          showProductsWithCustomPriceOnly={showProductsWithCustomPriceOnly}
        />
      </Grid>
      {isLoading ? (
        <Spinner />
      ) : (
        <ProductsTable
          searchString={searchString}
          activePage={currentPage}
          handlePageChange={handlePageChange}
          products={products}
          categoriesIds={categoriesIds}
          productsCount={productsCount}
          toggleSortOrder={toggleSortOrder}
          currentSortOrder={currentSortOrder}
        />
      )}
    </div>
  );
};

export default compose(withTranslation(), withStyles(styles), withRouter)(ProductsList);
