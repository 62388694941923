import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { validateAddFridgeForm } from '../../validators/formValidators';
import * as fridgesActions from '../../../redux/modules/fridges/fridgesActions';
import UpdateFridge from './UpdateFridge';
import { getRouteParamFromUrl } from '../../helpers/getParamsFromUrl';
import { showNotification } from '../../helpers/notificationHelper';
import notificationTypes from '../../helpers/notificationTypes';
import i18n from 'i18next';

const selector = formValueSelector('updateFridgeForm');

const mapStateToProps = (state, ownProps) => ({
  name: selector(state, 'name'),
  address: selector(state, 'address'),
  description: selector(state, 'description'),
  hasLock: selector(state, 'hasLock'),
  pricingCoefficient: selector(state, 'pricingCoefficient'),
  externalId: selector(state, 'externalId'),
  isLoading: state.fridges.isLoading,
  fridgeId: getRouteParamFromUrl(ownProps, 'id'),
  selectedFridge: state.fridges.selectedFridge,
  isHidden: selector(state, 'isHidden'),
  latitude: selector(state, 'latitude'),
  longitude: selector(state, 'longitude'),
  initialValues: {
    ...state.fridges.selectedFridge,
    latitude: state.fridges.selectedFridge.location?.latitude,
    longitude: state.fridges.selectedFridge.location?.longitude,
    lockingDeviceNumber: state.fridges.selectedFridge.lockingDeviceNumber && {
      value: state.fridges.selectedFridge.lockingDeviceNumber,
      label: state.fridges.selectedFridge.lockingDeviceNumber,
    },
  },
});

const mapDispatchToProps = {
  updateFridge: fridgesActions.updateFridge,
  getFridgeById: fridgesActions.getFridgeById,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'updateFridgeForm',
    validate: validateAddFridgeForm,
    onSubmit: (payload, dispatch, props) => {
      const location = {
        latitude: payload.latitude ? Number(payload.latitude) : null,
        longitude: payload.longitude ? Number(payload.longitude) : null,
      };
      props
        .updateFridge(
          Number(payload.number),
          props.selectedFridge.id,
          payload.name,
          payload.address,
          payload.description,
          Number(payload.pricingCoefficient),
          payload.externalId,
          payload.hasLock,
          payload.controllerDeviceAddress,
          payload.lockingDeviceNumber,
          location,
          payload.isHidden,
        )
        .then(() => props.history.push('/fridges'))
        .catch((error) => {
          showNotification(notificationTypes.error, error, i18n.t('Changes were not saved'));
        });
    },
    enableReinitialize: true,
  }),
)(UpdateFridge);
