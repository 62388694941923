import { connect } from 'react-redux';
import * as usersActions from './../../../../redux/modules/users/usersActions';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import UsersSummary from './UsersSummary';

const mapStateToProps = (state, props) => {
  return {
    usersSummary: state.users.summary,
  };
};

const mapDispatchToProps = {
  getUsersSummary: usersActions.getUsersSummary,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(UsersSummary);
