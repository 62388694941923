import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import FilterOrdersForm from './FilterOrdersForm';
import { toShortDateTime } from '../../../helpers/dateFormatter';
import { dateRangeOptions } from '../../../fields/Select/DateRangeSelectField';
import { getQueryString } from '../../../helpers/queryHelper';

const mapStateToProps = (state, ownProps) => {
  const selectedUser = state.users.users.find((u) => u.id === ownProps.userId);
  return {
    initialValues: {
      dateRange: dateRangeOptions.find(
        (o) =>
          toShortDateTime(o.value.endDate) === ownProps.endDate &&
          toShortDateTime(o.value.startDate) === ownProps.startDate,
      ),
      selectedFridges: state.fridges.fridges
        .filter((f) => ownProps.fridgesIds.includes(f.id))
        .map((f) => ({ value: f.id, label: `#${f.number}, ${f.name}` })),
      orderStatus: ownProps.orderStatus && { value: ownProps.orderStatus, label: ownProps.orderStatus },
      user: selectedUser && { value: selectedUser.id, label: `${selectedUser.firstName} ${selectedUser.lastName}` },
    },
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  reduxForm({
    form: 'filterOrdersForm',
    onSubmit: (payload, dispatch, props) => {
      const queryString = getQueryString({
        page: 1,
        startDate: payload.dateRange ? toShortDateTime(payload.dateRange.value.startDate) : null,
        endDate: payload.dateRange ? toShortDateTime(payload.dateRange.value.endDate) : null,
        fridges: payload.selectedFridges,
        orderStatus: payload.orderStatus && payload.orderStatus.value,
        userId: payload.user ? payload.user.value : null,
      });

      props.history.push({
        pathname: props.history.location.pathname,
        search: queryString,
      });
    },
    enableReinitialize: true,
  }),
)(FilterOrdersForm);
