import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { validateSetProductCustomPriceForm } from '../../validators/formValidators';
import * as productsActions from '../../../redux/modules/products/productsActions';
import { showNotification } from '../../helpers/notificationHelper';
import notificationTypes from '../../helpers/notificationTypes';
import i18n from 'i18next';
import SetProductCustomPrice from './SetProductCustomPrice';
import { getRouteParamFromUrl, getQueryParamFromUrl } from '../../helpers/getParamsFromUrl';

const selector = formValueSelector('setProductCustomPrice');

const mapStateToProps = (state, ownProps) => {
  const fridgeId = getQueryParamFromUrl(ownProps, 'fridgeId');
  return {
    isLoading: state.products.isLoading,
    price: selector(state, 'price'),
    productId: getRouteParamFromUrl(ownProps, 'id'),
    fridgeId: fridgeId,
    initialValues: {
      price: state.products.fridgePrice,
      fridge: state.fridges.fridges
        .filter((f) => fridgeId === f.id)
        .map((f) => ({ value: f.id, label: `#${f.number}, ${f.name}` }))[0],
    },
  };
};

const mapDispatchToProps = {
  getProductCustomPriceInFridge: productsActions.getPorductCustomPriceInFridge,
  setProductCustomPrice: productsActions.setProductCustomPrice,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'setProductCustomPrice',
    validate: validateSetProductCustomPriceForm,
    onSubmit: (payload, dispatch, props) => {
      props
        .setProductCustomPrice(props.productId, payload.fridge.value, Number(payload.price))
        .then(() => props.history.push(`/products/${props.productId}/customPrices`))
        .catch((error) => {
          showNotification(notificationTypes.error, error, i18n.t('Failed to add product custom price.'));
        });
    },
    enableReinitialize: true,
  }),
)(SetProductCustomPrice);
