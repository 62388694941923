import React, { useState } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/es/styles';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import DateRangeSelectField, { dateRangeOptions } from '../../../fields/Select/DateRangeSelectField';
import commonStyles from '../../../helpers/commonStyles';
import DateRangePicker from '../../../fields/DateRangePicker';
import dateOptions from '../../../helpers/dateRangeOptions';
import IconButton from '@material-ui/core/IconButton';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import PlainInputField from '../../../fields/Input/PlainInputField';
import FridgesSelectFieldWithPagination from '../../../fields/SelectWithPagination/FridgesSelectFieldWithPagination';

const styles = (theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.white,
  },
  input: {
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    fontFamily: theme.typography.fontFamily,
  },
  filterContainer: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  filterButton: {
    marginLeft: '10px',
  },
  dataPicker: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
});

const FilterUsersForm = (props) => {
  const { classes, handleSubmit, t, fridgesIds } = props;
  const [isManuallyInput, setManuallyInput] = useState(false);
  const chooseManuallyOption = dateRangeOptions.find((o) => o.key === dateOptions.manually);

  const handleDateRangeSelectChanged = (event) => {
    if (event === chooseManuallyOption) {
      setManuallyInput(true);
    } else {
      setManuallyInput(false);
    }
  };

  const dateRangeChanged = (start, end) => {
    chooseManuallyOption.value.startDate = start;
    chooseManuallyOption.value.endDate = end;
  };
  return (
    <form className={classes.tableHeader} onSubmit={handleSubmit}>
      <div className={classes.container}>
        <div className={classes.filterContainer} style={{ marginLeft: 0 }}>
          <FridgesSelectFieldWithPagination
            name="selectedFridges"
            isMulti
            closeMenuOnSelect={false}
            fridgesIds={fridgesIds}
          />
        </div>
        <div className={classes.dataPicker}>{isManuallyInput && <DateRangePicker onChange={dateRangeChanged} />}</div>
        <div className={classes.filterContainer}>
          <DateRangeSelectField
            styles={commonStyles.selectComponentStyles}
            placeholder={t('All time')}
            name="dateRange"
            onChange={handleDateRangeSelectChanged}
          />
        </div>
        <div className={classes.filterContainer}>
          <IconButton className={classes.searchButton} size={'small'} type="submit">
            <SearchRoundedIcon />
          </IconButton>
          <PlainInputField
            className={classes.input}
            name="searchString"
            placeholder={t('Search')}
            inputProps={{ 'aria-label': 'naked' }}
          />
        </div>
        <Button className={classes.filterButton} variant={'outlined'} type="submit">
          {t('Apply filters')}
        </Button>
      </div>
    </form>
  );
};

export default compose(withTranslation(), withStyles(styles))(FilterUsersForm);
