export const downloadFile = (response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');

  //get file name from response content disposition section
  const contentDisposition = response.headers['content-disposition'];
  const fileNameBegin = contentDisposition.substring(contentDisposition.indexOf('filename') + 'filename'.length + 2);
  const fileName = fileNameBegin.substring(0, fileNameBegin.indexOf('"'));

  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};
