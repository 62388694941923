export const getProductsQueryString = (
  pageNumber,
  searchString,
  categoriesIds,
  selectedFridge,
  showProductsWithCustomPriceOnly,
) => {
  let categoriesQueryString =
    categoriesIds &&
    categoriesIds
      .map((v) => {
        return `&categories=${v.value || v}`;
      })
      .join('');

  let queryString = searchString
    ? categoriesIds
      ? `page=${pageNumber}&searchString=${searchString}${categoriesQueryString}`
      : `page=${pageNumber}&searchString=${searchString}`
    : categoriesIds
    ? `page=${pageNumber}${categoriesQueryString}`
    : `page=${pageNumber}`;

  if (selectedFridge) {
    queryString += `&fridgeId=${selectedFridge}`;
  }

  if (showProductsWithCustomPriceOnly) {
    queryString += `&showProductsWithCustomPriceOnly=${showProductsWithCustomPriceOnly}`;
  }

  return queryString;
};

export const getProductsQueryStringWithSorting = (
  pageNumber,
  searchString,
  categoriesIds,
  sortOrder,
  selectedFridge,
  showProductsWithCustomPriceOnly,
) => {
  let queryString = getProductsQueryString(
    pageNumber,
    searchString,
    categoriesIds,
    selectedFridge,
    showProductsWithCustomPriceOnly,
  );

  if (sortOrder) {
    queryString = getQueryStringWithDelimiter(queryString);
    queryString += `sortOrder=${sortOrder}`;
  }

  return queryString;
};

const getQueryStringWithDelimiter = (queryString) => {
  if (!queryString) {
    queryString = '?';
  } else {
    queryString += '&';
  }

  return queryString;
};
