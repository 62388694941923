import { connect } from 'react-redux';
import * as productsActions from './../../../redux/modules/products/productsActions';
import ProductsList from './ProductsList';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { getArrayParam, getPageNumber, getSearchString, getSortOrder } from '../../helpers/queryHelper';
import { getQueryParamFromUrl } from '../../helpers/getParamsFromUrl';

const mapStateToProps = (state, props) => {
  return {
    products: state.products.products,
    isLoading: state.products.isLoading,
    productsCount: state.products.productsCount,
    currentPage: getPageNumber(props),
    searchString: getSearchString(props),
    categoriesIds: getArrayParam(props, 'categories'),
    productCategories: state.products.productCategories,
    selectedFridgeId: getQueryParamFromUrl(props, 'fridgeId') || '',
    sortOrder: getSortOrder(props),
    showProductsWithCustomPriceOnly: getQueryParamFromUrl(props, 'showProductsWithCustomPriceOnly') || false,
  };
};

const mapDispatchToProps = {
  getProducts: productsActions.getProducts,
  getProductCategories: productsActions.getProductCategories,
  importProductsFromXml: productsActions.importProductsFromXml,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProductsList);
