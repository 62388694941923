import ORDERS_CONSTANTS from './ordersConstants';

const defaultState = {
  isLoading: false,
  excelReportLoading: false,
  excelReportIntervalIsValid: false,
  orders: [],
  ordersSummary: {
    totalOrders: 0,
    totalSum: 0,
  },
  currentPage: 1,
  ordersCount: 1,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ORDERS_CONSTANTS.ORDERS_PENDING:
      return handleRequestPending(state, action.payload);

    case ORDERS_CONSTANTS.EXCEL_REPORT_PENDING:
      return handleExcelReportPending(state, action.payload);

    case ORDERS_CONSTANTS.GET_ORDERS_HISTORY:
      return handleGetOrders(state, action.payload.orders, action.payload.currentPage, action.payload.ordersCount);

    case ORDERS_CONSTANTS.GET_ORDERS_SUMMARY:
      return handleGetOrdersSummary(state, action.payload);

    case ORDERS_CONSTANTS.TOGGLE_EXCEL_REPORT_INTERVAL_VALIDITY:
      return toggleExcelReportIntervalValidity(state, action.payload);

    default:
      return state;
  }
};

function handleRequestPending(state, pending) {
  return {
    ...state,
    isLoading: pending,
  };
}

function handleExcelReportPending(state, pending) {
  return {
    ...state,
    excelReportLoading: pending,
  };
}

function handleGetOrders(state, orders, currentPage, totalItems) {
  return {
    ...state,
    orders: orders,
    currentPage: currentPage,
    ordersCount: totalItems,
    isLoading: false,
  };
}

function handleGetOrdersSummary(state, ordersSummary) {
  return {
    ...state,
    ordersSummary: ordersSummary,
    isLoading: false,
  };
}

function toggleExcelReportIntervalValidity(state, excelReportIntervalIsValid) {
  return {
    ...state,
    excelReportIntervalIsValid,
  };
}
