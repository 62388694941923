import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Menu from '../../fields/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { globalThemeObject } from '../../styles/GlobalThemeObject';
import { useMediaPredicate } from 'react-media-hook';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  header: {
    width: '100%',
    minHeight: '58px',
    background: theme.palette.white,
    alignItems: 'center',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)',
    zIndex: 3,
    position: 'fixed',
    overflow: 'visible',
    top: 0,
    right: 0,
    left: 0,
  },
  contentWrapper: {
    width: '95%',
    minHeight: '58px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  contentRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  menuItemLink: {
    fontSize: '24px',
    color: theme.palette.primary.dark,
    marginLeft: '10px',
    cursor: 'pointer',
    textDecoration: 'none',
    borderRadius: '11px',
  },
  currentPage: {
    color: theme.palette.secondary.dark,
  },
  userInfo: {
    fontWeight: 600,
    color: theme.palette.primary.dark,
    marginLeft: '10px',
    cursor: 'pointer',
  },
});

const HeaderBar = (props) => {
  const { classes, pageTitle, history } = props;
  const { t } = useTranslation();
  const menuWidth = 1200;

  const sellerId = localStorage.getItem('sellerId');
  const headerBarLinks = [
    {
      title: t('Fridges'),
      redirectUrl: '/fridges',
    },
    {
      title: t('Products'),
      redirectUrl: '/products',
    },
    {
      title: t('Categories'),
      redirectUrl: '/products/categories',
    },
    {
      title: t('Orders'),
      redirectUrl: '/orders',
    },
    {
      title: t('Users'),
      redirectUrl: '/users',
    },
    {
      title: t('Rating'),
      redirectUrl: '/products/rating',
    },
    // {
    //   title: t("Import"),
    //   redirectUrl: "/products/import",
    // },
  ];
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const sellerLogoUrl = localStorage.getItem('sellerLogoUrl');
  const burgerMenuShouldBeShown = useMediaPredicate(`(max-width: ${menuWidth}px)`);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    document.title = 'EasyLunch';

    history.push('/login');
  };

  const handleChangePassword = () => {
    history.push('/account/changepassword');
  };

  const renderHamburgerItems = () => {
    const items = [];
    headerBarLinks.forEach((el, i) => {
      items.push({
        key: i,
        label: el.title,
        handleClick: () => history.push(el.redirectUrl),
      });
    });
    return items;
  };

  return (
    <React.Fragment>
      <header className={classes.header}>
        <div className={classes.contentWrapper}>
          <div className={classes.contentLeft}>
            {sellerLogoUrl !== 'null' && <img src={`${sellerLogoUrl}`} alt={sellerId} />}
            {burgerMenuShouldBeShown ? (
              <div className={classes.contentLeft}>
                <Menu placement="bottom" options={renderHamburgerItems()} onToggle={() => setMenuOpen(!menuOpen)}>
                  {menuOpen ? (
                    <MenuOpenIcon style={{ color: globalThemeObject.palette.primary.dark }} />
                  ) : (
                    <MenuIcon style={{ color: globalThemeObject.palette.primary.dark }} />
                  )}
                </Menu>
                <Typography className={`${classes.menuItemLink} ${classes.currentPage}`} variant="subtitle2">
                  {pageTitle}
                </Typography>
              </div>
            ) : (
              headerBarLinks.map((link) => (
                <Typography key={link.title} variant="subtitle2">
                  <Link
                    className={`${classes.menuItemLink} ${link.title === pageTitle && classes.currentPage}`}
                    to={link.redirectUrl}
                  >
                    {link.title}
                  </Link>
                </Typography>
              ))
            )}
          </div>
          <div className={classes.contentRight}>
            <Menu
              withArrow
              placement="bottom-end"
              options={[
                {
                  key: 'changePassword',
                  label: t('Change password'),
                  handleClick: () => handleChangePassword(),
                },
                {
                  key: 'logout',
                  label: t('Log out'),
                  handleClick: () => handleLogout(),
                },
              ]}
            >
              <Typography variant="subtitle2" className={classes.userInfo}>
                {currentUser.email}
              </Typography>
            </Menu>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default compose(withRouter, withStyles(styles))(HeaderBar);
