import React from 'react';
import { Field } from 'redux-form';
import InnerSelectField from './InnerSelectField';
import moment from 'moment';
import i18next from 'i18next';

const DateRangeSelectField = (props) => (
  <Field name={props.name} options={dateRangeOptions} component={InnerSelectField} isClearable={true} {...props} />
);

export default DateRangeSelectField;

export const dateRangeOptions = [
  {
    key: 'today',
    label: i18next.t('Today'),
    value: {
      startDate: moment().startOf('day'),
      endDate: null,
    },
  },
  {
    key: 'yesterday',
    label: i18next.t('Yesterday'),
    value: {
      startDate: moment().subtract(1, 'd').startOf('day'),
      endDate: moment().subtract(1, 'd').endOf('day'),
    },
  },
  {
    key: 'lastweek',
    label: i18next.t('Last week'),
    value: {
      startDate: moment().subtract(6, 'days').startOf('day'),
      endDate: null,
    },
  },
  {
    key: 'currentmonth',
    label: i18next.t('Current month'),
    value: {
      startDate: moment().startOf('month'),
      endDate: null,
    },
  },
  {
    key: 'manually',
    label: i18next.t('Manually'),
    value: {
      startDate: moment().startOf('day'),
      endDate: null,
    },
  },
];
