import React from 'react';
import { TableRow, Tooltip, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Pagination from '@material-ui/lab/Pagination';
import ErrorIcon from '@material-ui/icons/Error';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import commonStyles from '../../helpers/commonStyles';
import paginationConstants from '../../helpers/paginationConstants';
import { toLongDateTime } from '../../helpers/dateFormatter';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  table: {
    minWidth: '700px',
  },
  headerRow: {
    backgroundColor: theme.palette.whiteblue,
  },
  tableContent: {
    backgroundColor: theme.palette.white,
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableHeader: {
    borderTop: commonStyles.tableHeaderTopBorder,
  },
  suspiciousRow: {
    backgroundColor: theme.palette.whitered,
  },
});

class UnlockHistoryTable extends React.Component {
  render() {
    const { classes, unlockHistory, handlePageChange, activePage, unlockRecordsCount, t, fridgeId } = this.props;
    const pagesCount = Math.ceil(unlockRecordsCount / paginationConstants.itemsPerPage);

    return (
      <React.Fragment>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.headerRow}>
              <TableCell className={classes.tableHeader} />
              <TableCell className={classes.tableHeader}>{t('Open date')}</TableCell>
              {!fridgeId && (
                <TableCell className={classes.tableHeader} align="right">
                  {t('Fridge')}
                </TableCell>
              )}
              <TableCell className={classes.tableHeader} align="right">
                {t('User')}
              </TableCell>
              <TableCell className={classes.tableHeader} align="right">
                {t('User phone number')}
              </TableCell>
              <TableCell className={classes.tableHeader} align="right">
                {t('User registration date')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unlockHistory.length !== 0 ? (
              unlockHistory.map((unlockRecord) => (
                <TableRow
                  key={unlockRecord.id}
                  className={unlockRecord.isSuspicious ? classes.suspiciousRow : classes.tableContent}
                >
                  <TableCell align="center" size="small">
                    {unlockRecord.isSuspicious && (
                      <Tooltip title={t('Suspicious unlock')} placement="bottom">
                        <ErrorIcon color="error" />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {toLongDateTime(unlockRecord.date)}
                  </TableCell>
                  {!fridgeId && (
                    <TableCell align="right">{`#${unlockRecord.fridge.number}, ${unlockRecord.fridge.name}`}</TableCell>
                  )}
                  <TableCell align="right">{unlockRecord.user.name}</TableCell>
                  <TableCell align="right">{unlockRecord.user.phoneNumber}</TableCell>
                  <TableCell align="right">{toLongDateTime(unlockRecord.user.registrationDate)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow className={classes.tableContent}>
                <TableCell align="center" colSpan={5}>
                  {t('There are no records for selected fridge.')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          className={classes.paginationContainer}
          size={'large'}
          variant="outlined"
          shape="rounded"
          count={pagesCount}
          page={activePage}
          onChange={handlePageChange}
          color="primary"
        />
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), withRouter, withStyles(styles))(UnlockHistoryTable);
