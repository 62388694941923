import React from 'react';
import { Field } from 'redux-form';
import InnerSelectField from './InnerSelectField';
import { globalThemeObject } from '../../styles/GlobalThemeObject';
import commonStyles from '../../helpers/commonStyles';

const CategoriesSelectField = (props) => (
  <Field
    name={props.name}
    options={props.categories.map((category) => {
      return { value: category.id, label: category.name };
    })}
    component={InnerSelectField}
    {...props}
  />
);

export default CategoriesSelectField;

export const customStyles = {
  control: (base, state) => ({
    ...base,
    fontSize: '16px',
    fontFamily: globalThemeObject.typography.fontFamily,
    cursor: 'pointer',
    backgroundColor: globalThemeObject.palette.whitegrey,
    '&:hover': { borderColor: globalThemeObject.palette.black },
    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxShadow: 'none',
  }),
  option: commonStyles.selectComponentStyles.option,
  menu: commonStyles.selectComponentStyles.menu,
};
