import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import commonStyles from '../../helpers/commonStyles';
import { withTranslation } from 'react-i18next';
import { globalThemeObject } from '../../styles/GlobalThemeObject';
import InnerSelectFieldWithPagination from './InnerSelectFieldWithPagination';
import { loadFridgesAsync } from './asyncSelectDataLoaders';
import MultiValueContainer from '../MultiValueContainer';
import { getFridges } from '../../../redux/modules/fridges/fridgesActions';
import paginationConstants from '../../helpers/paginationConstants';

const FridgesSelectFieldWithPagination = (props) => {
  const { t, fridgesIds } = props;

  const dispatch = useDispatch();
  const selectedFridges = useSelector((state) =>
    state.fridges.fridges
      .filter((f) => fridgesIds?.includes(f.id))
      .map((f) => ({ value: f.id, label: `#${f.number}, ${f.name}` })),
  );

  useEffect(() => {
    if (!fridgesIds) {
      return;
    }

    const fridgesCount = fridgesIds.length;
    const numberOfPages = Math.ceil(fridgesCount / paginationConstants.maxItemsPerPage);

    for (let page = 0; page < numberOfPages; page++) {
      const fridges = fridgesIds.slice(page * paginationConstants.maxItemsPerPage, paginationConstants.maxItemsPerPage);
      dispatch(getFridges(page + 1, paginationConstants.maxItemsPerPage, fridges));
    }
  }, [dispatch, fridgesIds]);

  return (
    <Field
      name={props.name}
      placeholder={t('All Fridges')}
      component={InnerSelectFieldWithPagination}
      styles={commonStyles.selectComponentStyles}
      isClearable={true}
      hideSelectedOptions={false}
      loadOptions={loadFridgesAsync}
      isSearchable={true}
      initialOptions={selectedFridges}
      components={{
        MultiValueContainer: MultiValueContainer,
      }}
      {...props}
    />
  );
};

export const customStyles = {
  control: (base, state) => ({
    ...base,
    fontSize: '16px',
    fontFamily: globalThemeObject.typography.fontFamily,
    cursor: 'pointer',
    backgroundColor: globalThemeObject.palette.whitegrey,
    '&:hover': { borderColor: globalThemeObject.palette.black },
    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxShadow: 'none',
  }),
  option: commonStyles.selectComponentStyles.option,
  menu: commonStyles.selectComponentStyles.menu,
  input: commonStyles.selectComponentStyles.input,
};

export default compose(withRouter, withTranslation())(FridgesSelectFieldWithPagination);
