import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import * as productsActions from './../../../../redux/modules/products/productsActions';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import SearchForm from '../../../common/Forms/SearchForm';
import paginationConstants from '../../../helpers/paginationConstants';

const selector = formValueSelector('searchProductCategoryForm');

const mapStateToProps = (state, ownProps) => ({
  title: selector(state, 'title'),
  initialValues: { title: ownProps.searchString },
});

const mapDispatchToProps = {
  getProductCategories: productsActions.getDetailedProductCategories,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'searchProductCategoryForm',
    onSubmit: (payload, dispatch, props) => {
      const queryString = payload.title ? `page=1&searchString=${payload.title}` : `page=1`;
      props.history.push({
        pathname: props.history.location.pathname,
        search: queryString,
      });
      props.getProductCategories(1, paginationConstants.itemsPerPage, payload.title);
    },
    enableReinitialize: true,
  }),
)(SearchForm);
