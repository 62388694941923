import React from 'react';
import { withStyles } from '@material-ui/core';
import LoginFormContainer from './LoginFormContainer';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
  },
  contentWrapper: {
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.lightblue,
    overflow: 'auto',
  },
});

class LoginPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.contentWrapper}>
            <LoginFormContainer />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(LoginPage);
