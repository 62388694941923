import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { toShortDateTime } from '../../../helpers/dateFormatter';
import { dateRangeOptions } from '../../../fields/Select/DateRangeSelectField';
import { getQueryString } from '../../../helpers/queryHelper';
import FilterUsersForm from './FilterUsersForm';

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    dateRange: dateRangeOptions.find(
      (o) =>
        toShortDateTime(o.value.endDate) === ownProps.endDate &&
        toShortDateTime(o.value.startDate) === ownProps.startDate,
    ),
    searchString: ownProps.searchString,
    selectedFridges: state.fridges.fridges
      .filter((f) => ownProps.fridgesIds.includes(f.id))
      .map((f) => ({ value: f.id, label: `#${f.number}, ${f.name}` })),
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  reduxForm({
    form: 'filterUsersForm',
    onSubmit: (payload, dispatch, props) => {
      const queryString = getQueryString({
        page: 1,
        startDate: payload.dateRange ? toShortDateTime(payload.dateRange.value.startDate) : null,
        endDate: payload.dateRange ? toShortDateTime(payload.dateRange.value.endDate) : null,
        fridges: payload.selectedFridges,
        searchString: payload.searchString,
        sortOrder: props.sortOrder,
      });

      props.history.push({
        pathname: props.history.location.pathname,
        search: queryString,
      });
    },
    enableReinitialize: true,
  }),
)(FilterUsersForm);
