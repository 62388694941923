import USERS_CONSTANTS from './usersConstants';

const defaultState = {
  isLoading: false,
  users: [],
  currentPage: 1,
  usersCount: 1,
  summary: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case USERS_CONSTANTS.USERS_PENDING:
      return handleRequestPending(state, action.payload);

    case USERS_CONSTANTS.GET_USERS:
      return handleGetUsers(state, action.payload.users, action.payload.currentPage, action.payload.usersCount);

    case USERS_CONSTANTS.GET_USERS_SUMMARY:
      return handleGetUsersSummary(state, action.payload);
    case USERS_CONSTANTS.BAN_USER:
      return handleBanUser(state, action.payload);
    case USERS_CONSTANTS.UNBAN_USER:
      return handleUnbanUser(state, action.payload);
    default:
      return state;
  }
};

function handleRequestPending(state, pending) {
  return {
    ...state,
    isLoading: pending,
  };
}

function handleGetUsers(state, users, currentPage, totalItems) {
  return {
    ...state,
    users: users,
    currentPage: currentPage,
    usersCount: totalItems,
    isLoading: false,
  };
}

function handleGetUsersSummary(state, summary) {
  return {
    ...state,
    summary: summary,
  };
}

function handleBanUser(state, userId) {
  return {
    ...state,
    users: state.users.map((user) => (user.id === userId ? { ...user, isBanned: true } : user)),
    isLoading: false,
  };
}

function handleUnbanUser(state, userId) {
  return {
    ...state,
    users: state.users.map((user) => (user.id === userId ? { ...user, isBanned: false } : user)),
    isLoading: false,
  };
}
