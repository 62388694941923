import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import * as productsActions from '../../../redux/modules/products/productsActions';
import { validateAddUpdateProductCategoryForm } from '../../validators/formValidators';
import AddProductCategory from './AddProductCategory';
import { showNotification } from '../../helpers/notificationHelper';
import notificationTypes from '../../helpers/notificationTypes';
import i18n from 'i18next';

const selector = formValueSelector('addProductCategoryForm');

const mapStateToProps = (state) => ({
  name: selector(state, 'name'),
  isLoading: state.products.isLoading,
});

const mapDispatchToProps = {
  addProductCategory: productsActions.addProductCategory,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'addProductCategoryForm',
    validate: validateAddUpdateProductCategoryForm,
    onSubmit: (payload, dispatch, props) => {
      props
        .addProductCategory(payload)
        .then(() => props.history.push('/products/categories'))
        .catch((error) => {
          showNotification(notificationTypes.error, error, i18n.t('Failed to add category.'));
        });
    },
  }),
)(AddProductCategory);
