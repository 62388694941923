import React from 'react';
import { withStyles } from '@material-ui/core';
import HeaderBar from '../../common/HeaderBar/HeaderBar';
import ProductsRatingContainer from './ProductsRatingContainer';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    paddingTop: '58px',
  },
  contentWrapper: {
    display: 'flex',
    minHeight: 'calc(100vh - 58px)',
    minWidth: 'fit-content',
    width: '100%',
    flexDirection: 'column',
    backgroundColor: theme.palette.whitegrey,
  },
});

const ProductsRatingPage = (props) => {
  const { classes } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <HeaderBar pageTitle={t('Rating')} />
        <div className={classes.contentWrapper}>
          <ProductsRatingContainer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(ProductsRatingPage);
