import moment from 'moment';
import dateFormats from './dateFormats';

export const toLongDateTime = (date) => {
  if (date) {
    return moment(date).format(dateFormats.longDateTime);
  }

  return null;
};

export const toShortDateTime = (date) => {
  if (date) {
    // To enable date compatibility with all browsers we need to specify what format the string is in
    return moment(date, dateFormats.shortDateTime).format(dateFormats.shortDateTime);
  }

  return null;
};

export const toISODateTime = (date) => {
  if (date) {
    // To enable date compatibility with all browsers we to specify what format the string is in
    return moment(date, dateFormats.shortDateTime).toISOString();
  }

  return null;
};
