import React from 'react';
import Button from '@material-ui/core/Button';
import InputField from '../../fields/Input/InputField';
import { withStyles } from '@material-ui/core';
import FullScreenLoader from '../../common/ProgressIndicators/FullScreenLoader';
import { useTranslation } from 'react-i18next';
import { getMessageByErrorCode } from '../../helpers/getMessageByErrorCode';

const styles = (theme) => ({
  button: {
    fontSize: '18px',
    margin: '20px 0px',
    padding: '4px 16px',
    width: '40%',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
  },
  title: {
    color: theme.palette.primary.dark,
  },
  childElement: {
    width: '100%',
  },
  flexContainer: {
    width: '620px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
    backgroundColor: theme.palette.white,
    borderRadius: '4px',
    border: '1px solid transparent',
  },
  input: {
    border: 'none',
    width: '90%',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    marginTop: '3%',
    fontFamily: theme.typography.fontFamily,
  },
  spinner: {
    marginTop: '20px',
    marginBottom: '20px',
  },
});

const areEqual = (prevProps, props) => {
  return props.submitFailed && !props.error && props.isLoading === prevProps.isLoading;
};

const LoginForm = React.memo(function LoginForm(props) {
  const { classes, error, isLoading } = props;
  const { t } = useTranslation();
  return isLoading ? (
    <FullScreenLoader />
  ) : (
    <form onSubmit={props.handleSubmit}>
      <div className={classes.flexContainer}>
        <InputField className={classes.input} name="sellerId" label={t('App id')} variant="outlined" />
        <InputField className={classes.input} name="email" label={t('Email')} variant="outlined" />
        <InputField
          className={classes.input}
          name="password"
          type="password"
          label={t('Password')}
          variant="outlined"
        />
        {error &&
          error.map((err) => (
            <span key={err.code} className={classes.input} style={{ color: 'red', textAlign: 'center' }}>
              {getMessageByErrorCode(err.code)}
            </span>
          ))}
        <Button variant="contained" type="submit" className={classes.button}>
          {t('Log in')}
        </Button>
      </div>
    </form>
  );
}, areEqual);

export default withStyles(styles)(LoginForm);
