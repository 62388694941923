import ACCOUNT_CONSTANTS from './accountConstants';
import axios from 'axios';

export const changePassword = (payload) => (dispatch) => {
  dispatch({
    type: ACCOUNT_CONSTANTS.ACCOUNT_PENDING,
    payload: true,
  });
  return axios
    .put('/account/change-password', {
      currentPassword: payload.oldPassword,
      newPassword: payload.newPassword,
    })
    .then((response) =>
      dispatch({
        type: ACCOUNT_CONSTANTS.CHANGEPASSWORD_SUCCEEDED,
        payload: false,
      }),
    )
    .catch((error) => {
      dispatch({
        type: ACCOUNT_CONSTANTS.ACCOUNT_PENDING,
        payload: false,
      });
      throw error.response.data.errors;
    });
};
