import USERS_CONSTANTS from './usersConstants';
import axios from 'axios';
import moment from 'moment';
import dateFormats from '../../../components/helpers/dateFormats';
import { getQueryString } from '../../../components/helpers/queryHelper';
import { toISODateTime } from '../../../components/helpers/dateFormatter';

export const getUsers =
  (pageNumber, usersPerPage, searchString, startDate, endDate, fridgesIds, sortOrder, usersIds) => (dispatch) => {
    dispatch({
      type: USERS_CONSTANTS.USERS_PENDING,
      payload: true,
    });

    startDate = moment(startDate, dateFormats.shortDateTime).startOf('day');
    endDate = moment(endDate, dateFormats.shortDateTime).endOf('day');
    const queryString = getQueryString({
      startDate: toISODateTime(startDate),
      endDate: toISODateTime(endDate),
      fridges: fridgesIds,
      searchString: searchString,
      sortOrder: sortOrder,
      users: usersIds,
    });

    return axios
      .get(`/users?pageNumber=${pageNumber}&itemsPerPage=${usersPerPage}&${queryString.substr(1)}`)
      .then((response) =>
        dispatch({
          type: USERS_CONSTANTS.GET_USERS,
          payload: {
            users: response.data.items,
            usersCount: response.data.totalItems,
            currentPage: response.data.currentPage,
          },
        }),
      );
  };

export const getUsersSummary = () => (dispatch) => {
  dispatch({
    type: USERS_CONSTANTS.USERS_PENDING,
    payload: true,
  });

  return axios.get('/users/summary').then((response) =>
    dispatch({
      type: USERS_CONSTANTS.GET_USERS_SUMMARY,
      payload: {
        numberOfUsers: response.data.numberOfUsers,
      },
    }),
  );
};

export const banUser = (userId) => (dispatch) => {
  return axios.post(`/users/${userId}/ban`).then(() => {
    dispatch({
      type: USERS_CONSTANTS.BAN_USER,
      payload: userId,
    });
  });
};

export const unbanUser = (userId) => (dispatch) => {
  return axios.post(`/users/${userId}/unban`).then(() => {
    dispatch({
      type: USERS_CONSTANTS.UNBAN_USER,
      payload: userId,
    });
  });
};
