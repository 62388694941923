import React, { useEffect, useState } from 'react';
import { TableRow, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Spinner from '../../common/ProgressIndicators/Spinner';
import Table from '@material-ui/core/Table';
import TableCell from '../../common/Tables/TableCell';
import commonStyles from '../../helpers/commonStyles';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Pagination from '@material-ui/lab/Pagination';
import paginationConstants from '../../helpers/paginationConstants';
import Menu from '../../fields/Menu';
import ConfirmationModal from '../../Modals/ConfirmationModal/ConfirmationModal';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '96%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '20px',
    marginBottom: '10px',
    backgroundColor: theme.palette.white,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
    justifyContent: 'space-between',
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
    backgroundColor: theme.palette.white,
    minWidth: '1300px',
  },
  gridItem: {
    margin: '10px',
  },
  actionButton: {
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
    lineHeight: '1.5',
    height: '36px',
    marginLeft: '30px',
    width: '150px',
  },
  table: {
    minWidth: '1300px',
  },
  headerRow: {
    backgroundColor: theme.palette.whiteblue,
  },
  tableContent: {
    backgroundColor: theme.palette.white,
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableHeader: {
    borderTop: commonStyles.tableHeaderTopBorder,
  },
  title: {
    color: theme.palette.primary.dark,
  },
});

const ProductCustomPrices = (props) => {
  const {
    getProductById,
    productId,
    product,
    isLoading,
    classes,
    t,
    fridgePricesCount,
    handlePageChange,
    activePage,
    getPorductCustomPrices,
    currentPage,
    fridgePrices,
    history,
    deleteProductCustomPrice,
  } = props;
  const pagesCount = Math.ceil(fridgePricesCount / paginationConstants.itemsPerPage);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fridgePriceToDelete, setFridgePriceToDelete] = useState(null);

  const handleEditCustomPrice = (fridgeId) => {
    history.push({
      pathname: history.location.pathname + '/add',
      search: `fridgeId=${fridgeId}`,
    });
  };

  const handleDeleteCustomPrice = (fridge) => {
    setIsModalOpen(true);
    setFridgePriceToDelete(fridge);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setFridgePriceToDelete(null);
  };

  const handleModalSubmit = () => {
    deleteProductCustomPrice(productId, fridgePriceToDelete.id);
    handleModalClose();
  };

  useEffect(() => {
    getProductById(productId);
    getPorductCustomPrices(currentPage, paginationConstants.itemsPerPage, productId);
  }, [getProductById, productId, currentPage, getPorductCustomPrices]);

  return (
    <div className={classes.root}>
      <Grid container direction="column" spacint={0} className={classes.container}>
        <Grid item className={classes.gridItem}>
          <Typography className={classes.title} variant="h6">
            {t('The list of custom prices for product {{barcodeValue}} {{name}}', {
              barcodeValue: product.barcodeValue || '',
              name: product.name || '',
            })}
          </Typography>
        </Grid>
        <Grid item className={classes.gridItem}>
          <Button
            startIcon={<AddIcon />}
            variant={'contained'}
            className={`${classes.actionButton}`}
            onClick={() => history.push(`/products/${productId}/customPrices/add`)}
          >
            {t('Add')}
          </Button>
        </Grid>
      </Grid>
      {isLoading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.headerRow}>
                <TableCell className={classes.tableHeader} align="left">
                  {t('Fridge')}
                </TableCell>
                <TableCell className={classes.tableHeader} align="right">
                  {t('Price')}
                </TableCell>
                <TableCell className={classes.tableHeader} />
              </TableRow>
            </TableHead>
            <TableBody>
              {fridgePrices?.map((fpp) => (
                <TableRow key={fpp.fridge.id} className={classes.tableContent}>
                  <TableCell align="left">{`#${fpp.fridge.number}, ${fpp.fridge.name}`}</TableCell>
                  <TableCell align="right">{fpp.price}</TableCell>
                  <TableCell align="right">
                    <Menu
                      withArrow
                      options={[
                        {
                          key: 'edit',
                          label: t('Edit'),
                          handleClick: () => handleEditCustomPrice(fpp.fridge.id),
                        },
                        {
                          key: 'delete',
                          label: t('Delete'),
                          handleClick: () => handleDeleteCustomPrice(fpp.fridge),
                        },
                      ]}
                    >
                      {t('Action')}
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            className={classes.paginationContainer}
            size={'large'}
            variant="outlined"
            shape="rounded"
            count={pagesCount}
            page={activePage}
            onChange={handlePageChange}
            color="primary"
          />
          <ConfirmationModal
            isOpen={isModalOpen}
            confirmButtonName={t('Yes')}
            handleClose={handleModalClose}
            handleSubmit={handleModalSubmit}
            modalTitle={t(
              'Are you shure you want to delete the custom price for product {{productName}} in fridge #{{fridgeNumber}} {{fridgeName}}',
              {
                productName: product.name || '',
                fridgeNumber: fridgePriceToDelete?.number || '',
                fridgeName: fridgePriceToDelete?.name || '',
              },
            )}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default compose(withTranslation(), withStyles(styles), withRouter)(ProductCustomPrices);
