import React, { useEffect } from 'react';
import Spinner from '../../common/ProgressIndicators/Spinner';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import InputField from '../../fields/Input/InputField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import FridgesSelectFieldWithPagination, {
  customStyles,
} from '../../fields/SelectWithPagination/FridgesSelectFieldWithPagination';

const styles = (theme) => ({
  button: {
    fontSize: '18px',
    padding: '4px 16px',
    width: '60%',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
  },
  title: {
    color: theme.palette.primary.dark,
  },
  childElement: {
    width: '100%',
    marginLeft: '50px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  flexContainer: {
    minWidth: '650px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    border: 'none',
    width: '600px',
    minWidth: '350px',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '2% 5% 1% 5%',
    fontFamily: theme.typography.fontFamily,
  },
});

const SetProductCustomPrice = (props) => {
  const { classes, history, pristine, isLoading, getProductCustomPriceInFridge, fridgeId, productId } = props;
  const { t } = useTranslation();

  useEffect(() => {
    getProductCustomPriceInFridge(productId, fridgeId);
  }, [getProductCustomPriceInFridge, productId, fridgeId]);

  const handleFridgeChange = (fridgeId) => {
    history.replace({
      pathname: history.location.pathname,
      search: `fridgeId=${fridgeId}`,
    });
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <form className={classes.flexContainer} onSubmit={props.handleSubmit}>
      <div className={classes.childElement}>
        <Typography className={classes.title} color={'primary'} variant={'h5'}>
          {t('Set custom price')}
        </Typography>
      </div>
      <FridgesSelectFieldWithPagination
        className={classes.input}
        styles={customStyles}
        name="fridge"
        placeholder={t('Fridge')}
        isClearable={false}
        onChange={(fridge) => handleFridgeChange(fridge.value)}
        fridgesIds={[fridgeId]}
      />
      <InputField className={classes.input} name="price" label={t('Price')} variant="outlined" />
      <div className={classes.childElement}>
        <Button disabled={pristine} variant="contained" type="submit" className={classes.button}>
          {t('Save')}
        </Button>
      </div>
    </form>
  );
};

export default withStyles(styles)(SetProductCustomPrice);
