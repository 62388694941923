import { compose } from 'redux';
import { withRouter } from 'react-router';
import { getPageNumber } from '../../helpers/queryHelper';
import { connect } from 'react-redux';
import * as fridgesActions from './../../../redux/modules/fridges/fridgesActions';
import { getRouteParamFromUrl } from '../../helpers/getParamsFromUrl';
import HealthChecks from './HealthChecks';

const mapStateToProps = (state, props) => {
  return {
    fridge: state.fridges.selectedFridge,
    isLoading: state.fridges.isLoading,
    healthChecksCount: state.fridges.healthChecksCount,
    currentPage: getPageNumber(props),
    healthChecks: state.fridges.healthChecks,
    fridgeId: getRouteParamFromUrl(props, 'id'),
  };
};

const mapDispatchToProps = {
  getFridgeHealthChecks: fridgesActions.getFridgeHealthChecks,
  getFridgeById: fridgesActions.getFridgeById,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(HealthChecks);
