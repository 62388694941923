import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import commonStyles from '../../helpers/commonStyles';
import { withTranslation } from 'react-i18next';
import InnerSelectFieldWithPagination from './InnerSelectFieldWithPagination';
import { loadUsersAsync } from './asyncSelectDataLoaders';
import MultiValueContainer from '../MultiValueContainer';
import { getUsers } from '../../../redux/modules/users/usersActions';
import paginationConstants from '../../helpers/paginationConstants';

const UsersSelectFieldWithPagination = (props) => {
  const { t, usersIds } = props;

  const dispatch = useDispatch();
  const selectedUsers = useSelector((state) =>
    state.users.users
      .filter((u) => usersIds.includes(u.id))
      .map((u) => ({ value: u.id, label: `${u.firstName} ${u.lastName}` })),
  );

  useEffect(() => {
    const usersCount = usersIds.length;
    const numberOfPages = Math.ceil(usersCount / paginationConstants.maxItemsPerPage);

    for (let page = 0; page < numberOfPages; page++) {
      const users = usersIds.slice(page * paginationConstants.maxItemsPerPage, paginationConstants.maxItemsPerPage);
      dispatch(getUsers(page + 1, paginationConstants.maxItemsPerPage, null, null, null, null, null, users));
    }
  }, [dispatch, usersIds]);

  return (
    <Field
      name={props.name}
      placeholder={t('All users')}
      component={InnerSelectFieldWithPagination}
      styles={commonStyles.selectComponentStyles}
      isClearable={true}
      hideSelectedOptions={false}
      loadOptions={loadUsersAsync}
      isSearchable={true}
      initialOptions={selectedUsers}
      components={{
        MultiValueContainer: MultiValueContainer,
      }}
      {...props}
    />
  );
};

export default compose(withRouter, withTranslation())(UsersSelectFieldWithPagination);
