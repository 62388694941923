import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import Spinner from '../../common/ProgressIndicators/Spinner';
import UnlockHistoryTable from './UnlockHistoryTable';
import paginationConstants from '../../helpers/paginationConstants';
import { withTranslation } from 'react-i18next';
import FilterUnlockHistoryConatiner from './FilterFridgeUnlockHistory/FilterUnlockHistoryConatiner';
import {
  getFridgesUnlocksQueryString,
  getSelectedFridgeUnlocksQueryString,
} from '../../helpers/Fridges/fridgesUnlocksQueryHelper';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '96%',
    minWidth: 700,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '20px',
    marginBottom: '10px',
    backgroundColor: theme.palette.white,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
  },
  gridItem: {
    margin: '10px',
  },
  addProductButton: {
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
    marginLeft: '20px',
    lineHeight: '1.5',
  },
  title: {
    color: theme.palette.primary.dark,
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
    backgroundColor: theme.palette.white,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const UnlockHistory = (props) => {
  const {
    classes,
    unlockHistory,
    isLoading,
    unlockRecordsCount,
    fridgeId,
    getFridgeUnlockHistory,
    currentPage,
    onlySuspiciousUnlocks,
    t,
  } = props;

  useEffect(() => {
    getFridgeUnlockHistory(fridgeId, currentPage, paginationConstants.itemsPerPage, onlySuspiciousUnlocks);
  }, [currentPage, fridgeId, getFridgeUnlockHistory, onlySuspiciousUnlocks]);

  const handlePageChange = (event, pageNumber) => {
    let query;
    if (fridgeId) {
      query = getSelectedFridgeUnlocksQueryString(pageNumber, onlySuspiciousUnlocks, fridgeId);
    } else {
      query = getFridgesUnlocksQueryString(pageNumber, onlySuspiciousUnlocks);
    }
    props.history.push({
      pathname: props.history.location.pathname,
      search: query,
    });
    window.scrollTo(0, 0);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={classes.root}>
          <Grid container direction="row" spacing={0} className={classes.container}>
            <Grid item className={classes.gridItem}>
              <Typography className={classes.title} variant={'h6'}>
                {t('Fridges unlocks history')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={classes.gridItem}>
            <FilterUnlockHistoryConatiner fridgeId={fridgeId} />
          </Grid>
          <UnlockHistoryTable
            activePage={currentPage}
            handlePageChange={handlePageChange}
            unlockHistory={unlockHistory}
            unlockRecordsCount={unlockRecordsCount}
            fridgeId={fridgeId}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default compose(withStyles(styles), withTranslation(), withRouter)(UnlockHistory);
