import React from 'react';
import commonStyles from '../../helpers/commonStyles';
import MultiValueContainer from '../MultiValueContainer';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import InnerSelectField from './InnerSelectField';

const CategoriesMultiSelectField = (props) => {
  const { t } = props;
  return (
    <Field
      name={props.name}
      placeholder={t('All categories')}
      options={props.categories.map((category) => {
        return { value: category.id, label: category.name };
      })}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      component={InnerSelectField}
      components={{
        MultiValueContainer: MultiValueContainer,
      }}
      styles={commonStyles.selectComponentStyles}
      isSearchable={false}
    />
  );
};

export default withTranslation()(CategoriesMultiSelectField);
