import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import { TableRow, withStyles } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Pagination from '@material-ui/lab/Pagination';
import Badge from '@material-ui/core/Badge';
import paginationConstants from '../../helpers/paginationConstants';
import commonStyles from '../../helpers/commonStyles';
import TableCell from '../../common/Tables/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '../../fields/Menu';

const styles = (theme) => ({
  table: {
    minWidth: '1300px',
  },
  headerRow: {
    backgroundColor: theme.palette.whiteblue,
  },
  tableContent: {
    backgroundColor: theme.palette.white,
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableHeader: {
    borderTop: commonStyles.tableHeaderTopBorder,
  },
  badge: {
    transform: 'scale(0.7) translate(100%, -80%);',
    top: 12,
    right: -3,
  },
});

class ProductsTable extends React.Component {
  render() {
    const {
      classes,
      products,
      handlePageChange,
      activePage,
      productsCount,
      searchString,
      categoriesIds,
      t,
      toggleSortOrder,
      currentSortOrder,
    } = this.props;
    const pagesCount = Math.ceil(productsCount / paginationConstants.itemsPerPage);

    const editProduct = (productId) => {
      this.props.history.push(`/products/${productId}/edit`);
    };

    const viewProductDetails = (productId) => {
      this.props.history.push(`/products/${productId}/info`);
    };

    const showCustomPrices = (productId) => {
      this.props.history.push(`/products/${productId}/customPrices`);
    };

    const setCustomPrices = (productId, fridgeId) => {
      this.props.history.push(`/products/${productId}/customPrices/add`);
    };

    return (
      <React.Fragment>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.headerRow}>
              <TableCell className={classes.tableHeader}>{t('Product barcode')}</TableCell>
              <TableCell
                name={'name'}
                isSortable
                onChangeSort={toggleSortOrder}
                currentSort={currentSortOrder}
                className={classes.tableHeader}
                align="left"
              >
                {t('Product name')}
              </TableCell>
              <TableCell
                name={'category'}
                isSortable
                onChangeSort={toggleSortOrder}
                currentSort={currentSortOrder}
                className={classes.tableHeader}
                align="left"
              >
                {t('Product category')}
              </TableCell>
              <TableCell
                name={'price'}
                isSortable
                onChangeSort={toggleSortOrder}
                currentSort={currentSortOrder}
                className={classes.tableHeader}
                align="right"
              >
                {t('Product price')}
              </TableCell>
              <TableCell
                name={'productionPrice'}
                isSortable
                onChangeSort={toggleSortOrder}
                currentSort={currentSortOrder}
                className={classes.tableHeader}
                align="right"
              >
                {t('Product production cost')}
              </TableCell>
              <TableCell className={classes.tableHeader} />
              <TableCell className={classes.tableHeader} />
            </TableRow>
          </TableHead>
          <TableBody>
            {products.length !== 0
              ? products.map((product) => (
                  <TableRow key={product.id} className={classes.tableContent}>
                    <TableCell component="th" scope="row">
                      {product.barcodeValue}
                    </TableCell>
                    <TableCell align="left">{product.name}</TableCell>
                    <TableCell align="left">{product.categoryName}</TableCell>
                    <TableCell align="right">
                      {product.price?.isCustom ? (
                        <Tooltip title={t('Custom price is set for this fridge')}>
                          <Badge classes={{ badge: classes.badge }} color="primary" variant="dot">
                            {product.price?.value}
                          </Badge>
                        </Tooltip>
                      ) : (
                        product.price?.value
                      )}
                    </TableCell>
                    <TableCell align="right">{product.productionCost}</TableCell>
                    <TableCell align="left">
                      <Button
                        variant={'outlined'}
                        onClick={() => viewProductDetails(product.id)}
                        startIcon={<MoreHorizIcon />}
                      >
                        {t('Details')}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Menu
                        withArrow
                        options={[
                          {
                            key: 'edit',
                            label: t('Edit'),
                            handleClick: () => editProduct(product.id),
                          },
                          {
                            key: 'setCustomPrice',
                            label: t('Set custom price in fridge'),
                            handleClick: () => setCustomPrices(product.id),
                          },
                          {
                            key: 'listCustomPrices',
                            label: t('Show custom prices'),
                            handleClick: () => showCustomPrices(product.id),
                          },
                        ]}
                      >
                        {t('Action')}
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))
              : (searchString || categoriesIds) && (
                  <TableRow className={classes.tableContent}>
                    <TableCell align={'center'} colSpan={7}>
                      {t('Could not find any product for your request.')}
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
        <Pagination
          className={classes.paginationContainer}
          size={'large'}
          variant="outlined"
          shape="rounded"
          count={pagesCount}
          page={activePage}
          onChange={handlePageChange}
          color="primary"
        />
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), withRouter, withStyles(styles))(ProductsTable);
