import i18n from '../../i18n';

const emailIsValid = (email) => {
  const emailValidationRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  return emailValidationRegExp.test(email);
};

const valueIsPositiveNumber = (value) => {
  return !(isNaN(Number(value)) || Number(value) <= 0);
};

const valueIsPositiveNumberOrZero = (value) => {
  return !(isNaN(Number(value)) || Number(value) < 0);
};

export const validateAddUpdateProductForm = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = i18n.t('Please, fill this field');
  }
  if (!values.barcodeValue) {
    errors.barcodeValue = i18n.t('Please, fill this field');
  }
  if (values.barcodeValue?.length < 8 || values.barcodeValue?.length > 13) {
    errors.barcodeValue = i18n.t('Barcode must be between 8 and 13 characters long.');
  }
  if (values.barcodeValue && isNaN(values.barcodeValue)) {
    errors.barcodeValue = i18n.t('Barcode must contain only numbers');
  }
  if (values.proteins !== 0 && !values.proteins) {
    errors.proteins = i18n.t('Please, fill this field');
  }
  if (values.proteins && !valueIsPositiveNumberOrZero(values.proteins)) {
    errors.proteins = i18n.t('This field must be numeric and non-negative');
  }
  if (values.fats !== 0 && !values.fats) {
    errors.fats = i18n.t('Please, fill this field');
  }
  if (values.fats && !valueIsPositiveNumberOrZero(values.fats)) {
    errors.fats = i18n.t('This field must be numeric and non-negative');
  }
  if (values.carbohydrates !== 0 && !values.carbohydrates) {
    errors.carbohydrates = i18n.t('Please, fill this field');
  }
  if (values.carbohydrates && !valueIsPositiveNumberOrZero(values.carbohydrates)) {
    errors.carbohydrates = i18n.t('This field must be numeric and non-negative');
  }
  if (!values.price) {
    errors.price = i18n.t('Please, fill this field');
  }
  if (values.price && !valueIsPositiveNumber(values.price)) {
    errors.price = i18n.t('This field must be numeric and positive');
  }
  if (values.productionCost && !valueIsPositiveNumber(values.productionCost)) {
    errors.productionCost = i18n.t('This field must be numeric and positive');
  }
  if (!values.kiloCalories) {
    errors.kiloCalories = i18n.t('Please, fill this field');
  }
  if (values.kiloCalories && !valueIsPositiveNumber(values.kiloCalories)) {
    errors.kiloCalories = i18n.t('This field must be numeric and positive');
  }

  if (!values.ingredients) {
    errors.ingredients = i18n.t('Please, fill this field');
  }

  if (!values.category) {
    errors.category = i18n.t('Please, select a category');
  }

  return errors;
};

export const validateAddFridgeForm = (values) => {
  const errors = {};

  if (!values.number) {
    errors.number = i18n.t('Please, fill this field');
  }
  if (values.number && !valueIsPositiveNumber(values.number)) {
    errors.number = i18n.t('This field must be numeric and positive');
  }

  if (!values.name) {
    errors.name = i18n.t('Please, fill this field');
  }
  if (!values.address) {
    errors.address = i18n.t('Please, fill this field');
  }
  if (!values.pricingCoefficient) {
    errors.pricingCoefficient = i18n.t('Please, fill this field');
  }
  if (values.pricingCoefficient && !valueIsPositiveNumber(values.pricingCoefficient)) {
    errors.pricingCoefficient = i18n.t('This field must be numeric and positive');
  }
  if (
    values.pricingCoefficient &&
    !isNaN(Number(values.pricingCoefficient)) &&
    (values.pricingCoefficient <= 0 || values.pricingCoefficient > 100)
  ) {
    errors.pricingCoefficient = i18n.t('Price coefficient must take values from 0 to 100');
  }
  if (values.hasLock && !values.controllerDeviceAddress) {
    errors.controllerDeviceAddress = i18n.t('Please, fill this field');
  }
  if (values.hasLock && !values.lockingDeviceNumber) {
    errors.lockingDeviceNumber = i18n.t('Please, fill this field');
  }
  if (values.latitude && isNaN(Number(values.latitude))) {
    errors.latitude = i18n.t('Latitude must be numberic');
  }
  if (values.longitude && isNaN(Number(values.longitude))) {
    errors.longitude = i18n.t('Longitude must be numberic');
  }
  if ((!isNaN(Number(values.latitude)) && values.latitude <= -90) || values.latitude > 90) {
    errors.latitude = i18n.t('Latitude must take values from -90 to 90');
  }
  if ((!isNaN(Number(values.longitude)) && values.longitude <= -180) || values.longitude > 180) {
    errors.longitude = i18n.t('Longitude must take values from -180 to 180');
  }
  if (values.latitude && !values.longitude) {
    errors.longitude = i18n.t('Please, fill the longitude field');
  }
  if (!values.latitude && values.longitude) {
    errors.latitude = i18n.t('Please, fill the latitude field');
  }
  return errors;
};

export const validateLoginForm = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = i18n.t('Please, fill this field');
  }

  if (values.email && !emailIsValid(values.email)) {
    errors.email = i18n.t('Please, enter a valid email address');
  }

  if (!values.password) {
    errors.password = i18n.t('Please, fill this field');
  }

  return errors;
};

export const validateAddUpdateProductCategoryForm = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = i18n.t('Please, fill this field');
  }

  return errors;
};

export const validateChangePasswordForm = (values) => {
  const errors = {};

  if (!values.oldPassword) {
    errors.oldPassword = i18n.t('Please, fill this field');
  }

  if (String(values.oldPassword).length < 7) {
    errors.oldPassword = i18n.t('Password must be at least 7 characters long');
  }

  if (!values.newPassword) {
    errors.newPassword = i18n.t('Please, fill this field');
  }

  if (String(values.newPassword).length < 7) {
    errors.newPassword = i18n.t('Password must be at least 7 characters long');
  }

  if (!values.confirmNewPassword) {
    errors.confirmNewPassword = i18n.t('Please, fill this field');
  }

  if (values.newPassword !== values.confirmNewPassword) {
    errors.confirmNewPassword = i18n.t('Passwords must match');
  }

  return errors;
};

export const validateSetProductCustomPriceForm = (values) => {
  const errors = {};

  if (!values.fridge) {
    errors.fridge = i18n.t('Please, fill this field');
  }

  if (!values.price) {
    errors.price = i18n.t('Please, fill this field');
  }

  if (values.price && !valueIsPositiveNumber(values.price)) {
    errors.price = i18n.t('This field must be numeric and positive');
  }

  return errors;
};
