import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import Spinner from '../../common/ProgressIndicators/Spinner';
import ProductsRatingTable from './ProductsRatingTable';
import paginationConstants from '../../helpers/paginationConstants';
import FilterProductsRatingFormContainer from './FilterProductsRating/FilterProductsRatingContainer';
import { getQueryString } from '../../helpers/queryHelper';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '96%',
    minWidth: 700,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '20px',
    marginBottom: '10px',
    backgroundColor: theme.palette.white,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
    justifyContent: 'space-between',
  },
  gridItem: {
    margin: '10px',
  },
  addProductButton: {
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
    lineHeight: '1.5',
    height: '36px',
    marginLeft: '30px',
  },
  title: {
    color: theme.palette.primary.dark,
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
    backgroundColor: theme.palette.white,
    minWidth: '1300px',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const ProductsRating = (props) => {
  const {
    classes,
    products,
    isLoading,
    productsCount,
    currentPage,
    getProductsRating,
    fridgesIds,
    startDate,
    endDate,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    getProductsRating(currentPage, paginationConstants.itemsPerPage, startDate, endDate, fridgesIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    startDate,
    endDate,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fridgesIds.toString(),
    getProductsRating,
  ]);

  const handlePageChange = (event, pageNumber) => {
    const queryString = getQueryString({
      page: pageNumber,
      startDate: startDate,
      endDate: endDate,
      fridges: fridgesIds,
    });

    props.history.push({
      pathname: props.history.location.pathname,
      search: queryString,
    });
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container direction="row" spacing={0} className={classes.container}>
          <Grid item className={classes.gridItem}>
            <Typography className={classes.title} variant={'h6'}>
              {t("Products' rating")}
            </Typography>
          </Grid>
          <Grid item className={`${classes.gridItem}`}>
            <FilterProductsRatingFormContainer endDate={endDate} startDate={startDate} fridgesIds={fridgesIds} />
          </Grid>
        </Grid>
        {isLoading ? (
          <Spinner />
        ) : (
          <ProductsRatingTable
            activePage={currentPage}
            handlePageChange={handlePageChange}
            products={products}
            productsCount={productsCount}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default compose(withStyles(styles), withRouter)(ProductsRating);
