import React from 'react';
import { withStyles } from '@material-ui/core';
import HeaderBar from '../../common/HeaderBar/HeaderBar';
import ProductDetailsContainer from './ProductDetailsContainer';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    paddingTop: '58px',
  },
  contentWrapper: {
    display: 'flex',
    minHeight: 'calc(100vh - 58px)',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.whitegrey,
    overflow: 'auto',
  },
});

const ProductDetailsPage = (props) => {
  const { classes, t } = props;
  return (
    <div className={classes.root}>
      <HeaderBar pageTitle={t('Products')} />
      <div className={classes.contentWrapper}>
        <ProductDetailsContainer />
      </div>
    </div>
  );
};

export default compose(withTranslation(), withStyles(styles))(ProductDetailsPage);
