import Typography from '@material-ui/core/Typography';
import InputField from '../../fields/Input/InputField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  button: {
    fontSize: '18px',
    padding: '4px 16px',
    width: '60%',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
  },
  title: {
    color: theme.palette.primary.dark,
  },
  childElement: {
    width: '100%',
    marginLeft: '50px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  flexContainer: {
    minWidth: '650px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    border: 'none',
    width: '600px',
    minWidth: '350px',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '2% 5% 1% 5%',
    fontFamily: theme.typography.fontFamily,
  },
});

const AddUpdateProductCategoryForm = (props) => {
  const { classes, pageTitle, pristine } = props;
  const { t } = useTranslation();
  return (
    <form className={classes.flexContainer} onSubmit={props.handleSubmit}>
      <div className={classes.childElement}>
        <Typography className={classes.title} color={'primary'} variant={'h5'}>
          {pageTitle}
        </Typography>
      </div>
      <InputField className={classes.input} name="name" label={t('Name')} variant="outlined" />
      <div className={classes.childElement}>
        <Button disabled={pristine} variant="contained" type="submit" className={classes.button}>
          {t('Save')}
        </Button>
      </div>
    </form>
  );
};

export default withStyles(styles)(AddUpdateProductCategoryForm);
