import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  root: {
    width: '700px',
    margin: 'auto',
  },
  headline: {
    color: theme.palette.grey.dark,
    fontWeight: 'bold',
  },
  description: {
    color: theme.palette.grey.dark,
    marginBottom: '20px',
    whiteSpace: 'pre-wrap',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    margin: '0 10px',
  },
  closeButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
});

const WarningModal = (props) => {
  const { isOpen, handleClose, modalTitle, modalDescription, confirmButtonName, classes } = props;

  return (
    <Dialog open={isOpen} onClose={handleClose} className={classes.root}>
      <DialogTitle id="form-dialog-title">
        <p className={classes.headline}>{modalTitle}</p>
      </DialogTitle>
      <DialogContent>
        <div className={classes.description}>{modalDescription}</div>
        <div className={classes.buttonWrapper}>
          <Button
            color={'secondary'}
            variant="contained"
            onClick={handleClose}
            data-cy="confirm-btn"
            className={`${classes.button} ${classes.closeButton}`}
          >
            {confirmButtonName || 'OK'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(WarningModal);
