import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/es/styles';
import Button from '@material-ui/core/Button';
import DateRangeSelectField from '../../../fields/Select/DateRangeSelectField';
import commonStyles from '../../../helpers/commonStyles';
import DateRangePicker from '../../../fields/DateRangePicker';
import { useTranslation } from 'react-i18next';
import dateOptions from '../../../helpers/dateRangeOptions';
import { dateRangeOptions } from '../../../fields/Select/DateRangeSelectField';
import FridgesSelectFieldWithPagination from '../../../fields/SelectWithPagination/FridgesSelectFieldWithPagination';
import OrderStatusSelectField from '../../../fields/Select/OrderStatusSelectField';
import UsersSelectFieldWithPagination from '../../../fields/SelectWithPagination/UsersSelectFieldWithPagination';

const styles = (theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    fontFamily: theme.typography.fontFamily,
  },
  filterContainer: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: '5px',
  },
  filterButton: {
    marginLeft: '10px',
  },
  dataPeeker: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const FilterOrdersForm = (props) => {
  const { classes, handleSubmit, fridgesIds, userId } = props;
  const [isManuallyInput, setManuallyInput] = useState(false);
  const { t } = useTranslation();

  const chooseManuallyOption = dateRangeOptions.find((o) => o.key === dateOptions.manually);

  const handleDateRangeSelectChanged = (event) => {
    if (event === chooseManuallyOption) {
      setManuallyInput(true);
    } else {
      setManuallyInput(false);
    }
  };

  const dateRangeChanged = (start, end) => {
    chooseManuallyOption.value.startDate = start;
    chooseManuallyOption.value.endDate = end;
  };

  return (
    <form className={classes.tableHeader} onSubmit={handleSubmit}>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.filterContainer}>
            <UsersSelectFieldWithPagination name="user" usersIds={[userId]} />
          </div>
          <div className={classes.filterContainer}>
            <FridgesSelectFieldWithPagination
              name="selectedFridges"
              isMulti
              closeMenuOnSelect={false}
              fridgesIds={fridgesIds}
            />
          </div>
          <div className={classes.filterContainer}>
            <DateRangeSelectField
              styles={commonStyles.selectComponentStyles}
              placeholder={t('All time')}
              name="dateRange"
              onChange={handleDateRangeSelectChanged}
            />
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.dataPeeker}>{isManuallyInput && <DateRangePicker onChange={dateRangeChanged} />}</div>
          <div className={classes.filterContainer}>
            <OrderStatusSelectField
              name="orderStatus"
              placeholder={t('Order status')}
              styles={commonStyles.selectComponentStyles}
            />
          </div>
        </div>
      </div>
      <Button className={classes.filterButton} variant={'outlined'} type="submit">
        {t('Apply filters')}
      </Button>
    </form>
  );
};

export default withStyles(styles)(FilterOrdersForm);
