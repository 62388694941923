import React from 'react';
import TextField from '@material-ui/core/TextField';

const InnerInputField = ({ isCentered, focusOnMount, input, label, required, meta: { touched, error }, ...custom }) => {
  let styles = {};
  if (isCentered) {
    styles.textAlign = 'center';
  }
  styles.fontSize = 14;
  const innerProps = { style: styles };

  return (
    <React.Fragment>
      <TextField
        autoFocus={focusOnMount}
        label={label}
        inputProps={innerProps}
        required={required}
        {...input}
        {...custom}
      />
      {touched && error && (
        <span style={{ color: 'red' }} {...custom}>
          {error}
        </span>
      )}
    </React.Fragment>
  );
};

export default InnerInputField;
