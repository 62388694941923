import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import LoginForm from './LoginForm';
import { SubmissionError } from 'redux-form';
import { validateLoginForm } from '../../validators/formValidators';
import * as loginActions from './../../../redux/modules/login/loginActions';
import * as sellersActions from './../../../redux/modules/sellers/sellersActions';

const selector = formValueSelector('loginForm');

const mapStateToProps = (state) => ({
  login: selector(state, 'email'),
  password: selector(state, 'password'),
  isLoading: state.login.isLoading,
});

const mapDispatchToProps = {
  loginUser: loginActions.loginUser,
  getSellerBasicInfo: sellersActions.getSellerBasicInfo,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'loginForm',
    validate: validateLoginForm,
    onSubmit: (payload, dispatch, props) => {
      return props
        .loginUser(payload.email, payload.password, payload.sellerId)
        .then((res) => {
          localStorage.setItem('accessToken', res.payload.accessToken);
          localStorage.setItem('refreshToken', res.payload.refreshToken);
          localStorage.setItem('accessTokenExpirationDate', res.payload.accessTokenExpirationDate);
          localStorage.setItem('sellerId', res.payload.currentUser.sellerId);
          localStorage.setItem('currentUser', JSON.stringify(res.payload.currentUser));
          props.getSellerBasicInfo(payload.sellerId).then((response) => {
            localStorage.setItem('sellerLogoUrl', response.payload.logoUrl);
            localStorage.setItem('sellerName', response.payload.name);

            props.history.push('/fridges');
          });
        })
        .catch((err) => {
          throw new SubmissionError({ _error: err });
        });
    },
  }),
)(LoginForm);
