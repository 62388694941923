import { connect } from 'react-redux';
import * as productsActions from './../../../redux/modules/products/productsActions';
import ProductsRating from './ProductsRating';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { getArrayParam, getDateParam, getPageNumber } from '../../helpers/queryHelper';

const mapStateToProps = (state, props) => {
  return {
    products: state.products.products,
    isLoading: state.products.isLoading,
    productsCount: state.products.productsCount,
    currentPage: getPageNumber(props),
    fridgesIds: getArrayParam(props, 'fridges'),
    endDate: getDateParam(props, 'endDate'),
    startDate: getDateParam(props, 'startDate'),
  };
};

const mapDispatchToProps = {
  getProductsRating: productsActions.getProductsRating,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProductsRating);
