import LOGIN_CONSTANTS from './loginConstants';
import axios from 'axios';

export const loginUser = (email, password, sellerId) => (dispatch) => {
  dispatch({
    type: LOGIN_CONSTANTS.LOGIN_PENDING,
    payload: true,
  });
  return axios
    .post('/account/sign-in', { email, password, sellerId })
    .then((response) =>
      dispatch({
        type: LOGIN_CONSTANTS.LOGIN_SUCCEEDED,
        payload: {
          accessToken: response.data.accessToken,
          currentUser: response.data.currentUser,
          refreshToken: response.data.refreshToken,
          accessTokenExpirationDate: response.data.accessTokenExpirationDate,
        },
      }),
    )
    .catch((error) => {
      dispatch({
        type: LOGIN_CONSTANTS.LOGIN_PENDING,
        payload: false,
      });
      throw error.response.data.errors;
    });
};
