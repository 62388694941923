export const globalThemeObject = {
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    white: '#ffffff',
    whitegrey: '#f7f7f7',
    grey: {
      light: '#f3f3f3',
      main: '#a9a9a9',
      dark: '#7f7f7f',
    },
    whiteblue: '#f4f8fd',
    lightblue: '#dfedff',
    black: '#000000',
    transperentblack: '#00000066',
    red: '#fc5757',
    whitered: '#ffebeb',
    secondary: {
      main: '#f8e41c',
      dark: '#CCCC00',
    },
    primary: {
      light: '#90EE90',
      main: '#008000',
      dark: '#006400',
    },
  },
  border: {
    lightgrey: '1px solid rgba(224, 224, 224, 1)',
    transperentblack: '1px solid #000000BB',
  },
  select: {
    lightblack: 'hsl(0 0% 20%)',
  },
};
