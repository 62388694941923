export default {
  PRODUCTS_PENDING: 'PRODUCTS_PENDING',
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_PRODUCT_BY_ID: 'GET_PRODUCT_BY_ID',
  GET_PRODUCT_CATEGORIES: 'GET_PRODUCT_CATEGORIES',
  GET_DETAILED_CATEGORIES: 'GET_DETAILED_CATEGORIES',
  GET_CATEGORY_BY_ID: 'GET_CATEGORY_BY_ID',
  GET_PRODUCTS_RATING: 'GET_PRODUCTS_RATING',
  GET_PRODUCT_CUSTOM_PRICES: 'GET_PRODUCT_CUSTOM_PRICES',
  GET_PRODUCT_CUSTOM_PRICE_IN_FRIDGE: 'GET_PRODUCT_CUSTOM_PRICE_IN_FRIDGE',
  DELETE_PRODUCT_CUSTOM_PRICE_IN_FRIDGE: 'DELETE_PRODUCT_CUSTOM_PRICE_IN_FRIDGE',
};
