import React, { useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { getImageUrl } from '../../../api/imageApi';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  imageUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '600px',
  },
  input: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
  description: {
    marginLeft: '15px',
    width: '440px',
  },
  descriptionTitle: {
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '22px',
    letterSpacing: '-0.2px',
  },
  descriptionMain: {
    fontSize: '17px',
    lineHeight: '27px',
    fontWeight: 'normal',
    color: theme.palette.black,
  },
  image: {
    maxWidth: '600px',
    cursor: 'pointer',
  },
  icon: {
    margitTop: '20px',
    marginLeft: '-5px',
    cursor: 'pointer',
  },
});

const InnerImageUploadField = (props) => {
  const { input, classes } = props;
  const [imgUrl, setImgUrl] = useState(input.value ? input.value : null);
  const inputEl = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (input.value) {
      setImgUrl(input.value);
    }
  }, [input.value]);

  const handleChange = async (e) => {
    const value = e.target.files[0];
    if (value) {
      const data = new FormData();
      data.append('image', value);
      const res = await getImageUrl(data);

      if (res.data) {
        setImgUrl(res.data);
        input.onChange(res.data);
      }
    }
  };

  return (
    <div className={classes.imageUpload}>
      <input ref={inputEl} className={classes.input} type={'file'} id="file" onChange={handleChange} />
      {imgUrl && <img src={imgUrl} className={classes.image} alt="" onClick={() => inputEl.current.click()} />}
      {!imgUrl && (
        <React.Fragment>
          <label htmlFor="file">
            <AttachFileIcon className={classes.icon} />
          </label>
          <div className={classes.description}>
            <Typography className={classes.descriptionMain} variant={'subtitle2'}>
              {t('Please select an image in one of the following formats: .jpg, .jpeg, .png')}
            </Typography>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default withStyles(styles)(InnerImageUploadField);
