import { getQueryParamFromUrl } from './getParamsFromUrl';
import moment from 'moment';
import dateFormats from './dateFormats';

export const getPageNumber = (props) => {
  const pageNumberQuery = getQueryParamFromUrl(props, 'page');

  if (pageNumberQuery && !isNaN(Number(pageNumberQuery))) {
    return Number(pageNumberQuery);
  } else {
    return 1;
  }
};

export const getSearchString = (props) => {
  const searchStringQuery = getQueryParamFromUrl(props, 'searchString');

  if (searchStringQuery) {
    return searchStringQuery;
  } else {
    return '';
  }
};

export const getSortOrder = (props) => {
  const sortOrderString = getQueryParamFromUrl(props, 'sortOrder');

  if (sortOrderString) {
    return sortOrderString;
  } else {
    return '';
  }
};

export const getArrayParam = (props, paramName) => {
  const query = getQueryParamFromUrl(props, paramName);

  if (query) {
    if (Array.isArray(query)) {
      return query;
    } else {
      return new Array(query);
    }
  } else {
    return [];
  }
};

export const getDateParam = (props, paramName) => {
  const dateQuery = getQueryParamFromUrl(props, paramName);

  const queryDate = moment(dateQuery, dateFormats.shortDateTime);

  if (queryDate && queryDate.isValid()) {
    return dateQuery;
  } else {
    return null;
  }
};

/**
 * Returns query string with params and values from params object
 *
 * @param {object} params The object of {queryParam : value} pairs.
 * @returns {string} Query string with the following format: "?param1=value1&param2=value2&array1=1&array2=2"
 * @example const queryString = getQueryString({
 *    param1: value1,
 *    param2: value2,
 *    array1: [1, 2, 3]
 * });
 */
export const getQueryString = (params) => {
  let queryString = '?';
  for (let [key, value] of Object.entries(params)) {
    if (!value) {
      continue;
    }

    if (Array.isArray(value)) {
      if (value.length === 0) {
        continue;
      }

      queryString += `${getArrayQueryString(key, value)}&`;
    } else {
      queryString += `${key}=${value}&`;
    }
  }

  return queryString.slice(0, -1);
};

const getArrayQueryString = (paramName, values) => {
  if (!values) {
    return '';
  }

  let queryString = values.map((v) => `${paramName}=${v?.value || v}`).join('&');
  return queryString;
};
