import axios from 'axios';
import paginationConstants from '../../helpers/paginationConstants';

const loadItemsAsync = async (loadedOptions, page, initialOptions, url) => {
  const loadedIds = [...initialOptions, ...loadedOptions].map((x) => x.value);
  const response = await axios.get(url);
  const options = response.data.items.filter((item) => !loadedIds.includes(item.id));
  const hasMore = response.data.totalItems - page * paginationConstants.maxItemsPerPage > 0;

  return {
    options: options,
    hasMore: hasMore,
    additional: {
      page: page + 1,
      initialOptions: [],
    },
  };
};

export const loadFridgesAsync = async (search, loadedOptions, { page, initialOptions }) => {
  const searchString = search || '';
  const url = `/fridges?pageNumber=${page}&itemsPerPage=${paginationConstants.maxItemsPerPage}&searchString=${searchString}`;
  const result = await loadItemsAsync(loadedOptions, page, initialOptions, url);

  const options = result.options.map((item) => ({
    value: item.id,
    label: `#${item.number}, ${item.name}`,
  }));

  return {
    options: loadedOptions.length === 0 ? [...initialOptions, ...options] : options,
    hasMore: result.hasMore,
    additional: result.additional,
  };
};

export const loadUsersAsync = async (search, loadedOptions, { page, initialOptions }) => {
  const searchString = search || '';
  const url = `/users?pageNumber=${page}&itemsPerPage=${paginationConstants.maxItemsPerPage}&searchString=${searchString}`;
  const result = await loadItemsAsync(loadedOptions, page, initialOptions, url);

  const options = result.options.map((item) => ({
    value: item.id,
    label: `${item.firstName} ${item.lastName}`,
  }));

  return {
    options: loadedOptions.length === 0 ? [...initialOptions, ...options] : options,
    hasMore: result.hasMore,
    additional: result.additional,
  };
};
