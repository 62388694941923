import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  loadingWrapper: {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  loadingCell: {
    border: 'none',
    paddingTop: '50px',
    paddingBottom: '50px',
    backgroundColor: theme.palette.whitegrey,
  },
});

const Spinner = (props) => {
  const { classes } = props;
  return (
    <div className={classes.loadingCell}>
      <div className={classes.loadingWrapper}>
        <CircularProgress size={60} thickness={2} color="secondary" />
      </div>
    </div>
  );
};
export default withStyles(styles)(Spinner);
