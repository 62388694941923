import FRIDGES_CONSTANTS from './fridgesConstants';

const defaultState = {
  fridges: [],
  isLoading: false,
  selectedFridge: {},
  unlockHistory: {
    unlockRecordsCount: 1,
    items: [],
    isLoading: false,
  },
  healthChecks: [],
  healthChecksCount: 1,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FRIDGES_CONSTANTS.GET_FRIDGES:
      return handleGetFridges(state, action.payload.fridges, action.payload.currentPage, action.payload.fridgesCount);

    case FRIDGES_CONSTANTS.FRIDGES_PENDING:
      return handleFridgesPending(state, action.payload);

    case FRIDGES_CONSTANTS.GET_FRIDGE_BY_ID:
      return handleGetFridgeById(state, action.payload);

    case FRIDGES_CONSTANTS.DELETE_FRIDGE:
      return handleDeleteFridge(state, action.payload);

    case FRIDGES_CONSTANTS.GET_FRIDGE_UNLOCK_HISTORY:
      return handleGetFridgeUnlockHistory(state, action.payload);

    case FRIDGES_CONSTANTS.GET_FRIDGE_HEALTH_CHECKS:
      return handleGetFridgeHealthChecks(state, action.payload);

    default:
      return state;
  }
};

function handleGetFridges(state, fridges, currentPage, totalItems) {
  return {
    ...state,
    fridges: fridges,
    currentPage: currentPage,
    fridgesCount: totalItems,
    isLoading: false,
  };
}

function handleFridgesPending(state, pending) {
  return {
    ...state,
    isLoading: pending,
  };
}

function handleGetFridgeById(state, payload) {
  return {
    ...state,
    selectedFridge: payload.fridge,
    isLoading: payload.isLoading,
  };
}

function handleDeleteFridge(state, payload) {
  return {
    ...state,
    isLoading: payload.isLoading,
    fridges: state.fridges.filter((fridge) => fridge.id !== payload.id),
  };
}

function handleGetFridgeUnlockHistory(state, payload) {
  return {
    ...state,
    unlockHistory: {
      unlockRecordsCount: payload.unlockHistory.totalItems,
      items: payload.unlockHistory.items,
      isLoading: payload.isLoading,
    },
  };
}

function handleGetFridgeHealthChecks(state, payload) {
  return {
    ...state,
    healthChecks: payload.healthChecks.items,
    healthChecksCount: payload.healthChecks.totalItems,
    isLoading: payload.isLoading,
  };
}
