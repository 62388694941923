import React from 'react';
import { withStyles } from '@material-ui/core/es/styles';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import IconButton from '@material-ui/core/IconButton';
import PlainInputField from '../../fields/Input/PlainInputField';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.white,
  },
  input: {
    border: 'none',
    overflow: 'auto',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    width: '100%',
    fontFamily: theme.typography.fontFamily,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    height: '36px',
    marginLeft: '10px',
  },
  searchButton: {
    minWidth: '40px',
    borderRadius: '50%',
  },
});

const SearchForm = (props) => {
  const { classes, handleSubmit } = props;
  const { t } = useTranslation();
  return (
    <form className={classes.tableHeader} onSubmit={handleSubmit}>
      <div className={classes.searchContainer}>
        <IconButton className={classes.searchButton} size={'small'} onClick={handleSubmit}>
          <SearchRoundedIcon />
        </IconButton>
        <PlainInputField
          className={classes.input}
          name="title"
          placeholder={t('Search')}
          inputProps={{ 'aria-label': 'naked' }}
        />
      </div>
    </form>
  );
};

export default withStyles(styles)(SearchForm);
