import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/es/styles';
import Button from '@material-ui/core/Button';
import DateRangeSelectField, { dateRangeOptions } from '../../../fields/Select/DateRangeSelectField';
import commonStyles from '../../../helpers/commonStyles';
import DateRangePicker from '../../../fields/DateRangePicker';
import dateOptions from '../../../helpers/dateRangeOptions';
import { useTranslation } from 'react-i18next';
import FridgesSelectFieldWithPagination from '../../../fields/SelectWithPagination/FridgesSelectFieldWithPagination';

const styles = (theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.white,
  },
  input: {
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    fontFamily: theme.typography.fontFamily,
  },
  filterContainer: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  filterButton: {
    marginLeft: '10px',
  },
  dataPicker: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
});

const FilterProductsRatingForm = (props) => {
  const { classes, handleSubmit, fridgesIds } = props;
  const [chooseManuallyIsSelected, setChooseManuallyIsSelected] = useState(false);
  const { t } = useTranslation();

  const chooseManuallyOption = dateRangeOptions.find((o) => o.key === dateOptions.manually);

  const handleDateRangeSelectChanged = (event) => {
    const manuallyIsSelected = event === chooseManuallyOption;
    setChooseManuallyIsSelected(manuallyIsSelected);
  };

  const dateRangeChanged = (start, end) => {
    chooseManuallyOption.value.startDate = start;
    chooseManuallyOption.value.endDate = end;
  };

  return (
    <form className={classes.tableHeader} onSubmit={handleSubmit}>
      <div className={classes.container}>
        <div className={classes.filterContainer}>
          <FridgesSelectFieldWithPagination
            name="selectedFridges"
            isMulti
            closeMenuOnSelect={false}
            fridgesIds={fridgesIds}
          />
        </div>
        <div className={classes.dataPeeker}>
          {chooseManuallyIsSelected && <DateRangePicker onChange={dateRangeChanged} />}
        </div>
        <div className={classes.filterContainer}>
          <DateRangeSelectField
            styles={commonStyles.selectComponentStyles}
            placeholder={t('All time')}
            name="dateRange"
            onChange={handleDateRangeSelectChanged}
          />
        </div>
        <Button className={classes.filterButton} variant={'outlined'} type="submit">
          {t('Apply filters')}
        </Button>
      </div>
    </form>
  );
};

export default withStyles(styles)(FilterProductsRatingForm);
