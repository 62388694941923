import ORDERS_CONSTANTS from './ordersConstants';
import axios from 'axios';
import { toISODateTime } from '../../../components/helpers/dateFormatter';
import { getQueryString } from '../../../components/helpers/queryHelper';
import dateFormats from '../../../components/helpers/dateFormats';
import moment from 'moment';
import { downloadFile } from '../../../components/helpers/downloadFile';

const excelReportIntervalIsValid = (startDate, endDate) => {
  if (!endDate || !endDate.isValid()) {
    endDate = moment().endOf('day');
  }

  //convert end date from 23.59 of previous day to 00.00 of the next day
  const endDateForMonthsComparison = endDate.add(1, 'd').startOf('day');

  if (!startDate || !startDate.isValid() || endDateForMonthsComparison.diff(startDate, 'months', true) > 2) {
    return false;
  }
  return true;
};

export const getOrders =
  (pageNumber, ordersPerPage, startDate, endDate, fridgesIds, orderStatus, userId) => (dispatch) => {
    dispatch({
      type: ORDERS_CONSTANTS.ORDERS_PENDING,
      payload: true,
    });

    startDate = moment(startDate, dateFormats.shortDateTime).startOf('day');
    endDate = moment(endDate, dateFormats.shortDateTime).endOf('day');

    if (!excelReportIntervalIsValid(startDate, endDate)) {
      dispatch({
        type: ORDERS_CONSTANTS.TOGGLE_EXCEL_REPORT_INTERVAL_VALIDITY,
        payload: false,
      });
    } else {
      dispatch({
        type: ORDERS_CONSTANTS.TOGGLE_EXCEL_REPORT_INTERVAL_VALIDITY,
        payload: true,
      });
    }

    const fridgesAndDateRangeQuery = getQueryString({
      startDate: toISODateTime(startDate),
      endDate: toISODateTime(endDate),
      fridges: fridgesIds,
      orderStatus: orderStatus,
      userId: userId,
    });

    return axios
      .get(`/orders?pageNumber=${pageNumber}&itemsPerPage=${ordersPerPage}&${fridgesAndDateRangeQuery.substr(1)}`)
      .then((response) =>
        dispatch({
          type: ORDERS_CONSTANTS.GET_ORDERS_HISTORY,
          payload: {
            orders: response.data.items,
            ordersCount: response.data.totalItems,
            currentPage: response.data.currentPage,
          },
        }),
      );
  };

export const getOrdersExcelReport = (startDate, endDate, fridgesIds, orderStatus, userId) => (dispatch) => {
  dispatch({
    type: ORDERS_CONSTANTS.EXCEL_REPORT_PENDING,
    payload: true,
  });

  startDate = moment(startDate, dateFormats.shortDateTime).startOf('day');
  endDate = moment(endDate, dateFormats.shortDateTime).endOf('day');

  if (!endDate || !endDate.isValid()) {
    endDate = moment().endOf('day');
  }

  const fridgesAndDateRangeQuery = getQueryString({
    startDate: toISODateTime(startDate),
    endDate: toISODateTime(endDate),
    fridges: fridgesIds,
    orderStatus: orderStatus,
    userId: userId,
  });

  return axios({
    url: `/orders/excel?${fridgesAndDateRangeQuery.substr(1)}`,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    dispatch({
      type: ORDERS_CONSTANTS.EXCEL_REPORT_PENDING,
      payload: false,
    });
    downloadFile(response);
  });
};

export const getOrdersSummary = (startDate, endDate, fridgesIds, orderStatus, userId) => (dispatch) => {
  dispatch({
    type: ORDERS_CONSTANTS.ORDERS_PENDING,
    payload: true,
  });
  startDate = moment(startDate, dateFormats.shortDateTime).startOf('day');
  endDate = moment(endDate, dateFormats.shortDateTime).endOf('day');
  const fridgesAndDateRangeQuery = getQueryString({
    startDate: toISODateTime(startDate),
    endDate: toISODateTime(endDate),
    fridges: fridgesIds,
    orderStatus: orderStatus,
    userId: userId,
  });

  return axios.get(`/orders/summary${fridgesAndDateRangeQuery}`).then((response) =>
    dispatch({
      type: ORDERS_CONSTANTS.GET_ORDERS_SUMMARY,
      payload: {
        totalOrders: response.data.totalOrders,
        totalSum: response.data.totalSum,
        totalPaidWithBonuses: response.data.totalPaidWithBonuses,
        totalPaidWithMoney: response.data.totalPaidWithMoney,
      },
    }),
  );
};
