import { connect } from 'react-redux';
import * as productsActions from '../../../../redux/modules/products/productsActions';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import ProductDefaultPrice from './ProductDefaultPrice';
import { getRouteParamFromUrl } from '../../../helpers/getParamsFromUrl';

const mapStateToProps = (state, ownProps) => {
  return {
    productId: getRouteParamFromUrl(ownProps, 'id'),
    product: state.products.selectedProduct,
  };
};

const mapDispatchToProps = {
  getProductById: productsActions.getProduct,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProductDefaultPrice);
