import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import Spinner from '../../common/ProgressIndicators/Spinner';
import paginationConstants from '../../helpers/paginationConstants';
import OrdersHistoryTable from './OrdersHistoryTable';
import FilterOrdersFormContainer from './FilterOrders/FilterOrdersFormContainer';
import { useTranslation } from 'react-i18next';
import { getQueryString } from '../../helpers/queryHelper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import sellersConstants from '../../helpers/sellersConstants';
import Tooltip from '@material-ui/core/Tooltip';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '96%',
    minWidth: '1564px',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '20px',
    marginBottom: '10px',
    backgroundColor: theme.palette.white,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
    justifyContent: 'space-between',
  },
  gridItem: {
    margin: '10px',
  },
  exportButton: {
    minWidth: '167px',
    width: '167px',
    marginLeft: '10px',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
    lineHeight: '1.5',
    height: '36px',
  },
  title: {
    color: theme.palette.primary.dark,
  },
  exportOrdersSpinner: {
    color: theme.palette.white,
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
    backgroundColor: theme.palette.white,
    minWidth: '1564px',
  },
});

const OrdersHistoryList = (props) => {
  const {
    classes,
    getOrders,
    getOrdersExcelReport,
    orders,
    isLoading,
    ordersCount,
    currentPage,
    fridgesIds,
    startDate,
    endDate,
    excelReportLoading,
    excelReportIntervalIsValid,
    orderStatus,
    userId,
  } = props;
  const iceicebabySellerIsSelected = localStorage.getItem('sellerId') === sellersConstants.iceicebaby;
  const { t } = useTranslation();

  useEffect(() => {
    getOrders(currentPage, paginationConstants.itemsPerPage, startDate, endDate, fridgesIds, orderStatus, userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, startDate, endDate, fridgesIds.toString(), getOrders, orderStatus, userId]);

  const handlePageChange = (event, pageNumber) => {
    const queryString = getQueryString({
      page: pageNumber,
      startDate: startDate,
      endDate: endDate,
      fridges: fridgesIds,
      orderStatus: orderStatus,
      userId: userId,
    });

    props.history.push({
      pathname: props.history.location.pathname,
      search: queryString,
    });
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container direction="row" spacing={0} className={classes.container}>
          <Grid item className={classes.gridItem}>
            <Typography className={classes.title} variant={'h6'}>
              {t('List of orders')}
            </Typography>
          </Grid>
          <Grid item className={`${classes.gridItem} ${classes.filtersContainer}`}>
            <FilterOrdersFormContainer
              endDate={endDate}
              startDate={startDate}
              fridgesIds={fridgesIds}
              orderStatus={orderStatus}
              userId={userId}
            />
            {iceicebabySellerIsSelected && (
              <Tooltip
                title={t('It is possible to export orders for a period not exceeding 2 months.')}
                placement="top-end"
              >
                <div>
                  <Button
                    disabled={!ordersCount || !excelReportIntervalIsValid}
                    variant={'contained'}
                    className={classes.exportButton}
                    onClick={() => getOrdersExcelReport(startDate, endDate, fridgesIds, orderStatus, userId)}
                  >
                    {excelReportLoading ? (
                      <CircularProgress size={'1.5rem'} className={classes.exportOrdersSpinner} />
                    ) : (
                      t('Export to Excel')
                    )}
                  </Button>
                </div>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        {isLoading ? (
          <Spinner />
        ) : (
          <OrdersHistoryTable
            activePage={currentPage}
            handlePageChange={handlePageChange}
            orders={orders}
            fridgesIds={fridgesIds}
            ordersCount={ordersCount}
            startDate={startDate}
            orderStatus={orderStatus}
            userId={userId}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default compose(withStyles(styles), withRouter)(OrdersHistoryList);
