import React from 'react';
import Spinner from '../../common/ProgressIndicators/Spinner';
import AddUpdateFridgeForm from '../../common/Forms/AddUpdateFridgeForm';
import { useTranslation } from 'react-i18next';

const AddFridge = (props) => {
  const { isLoading } = props;
  const { t } = useTranslation();
  return isLoading ? <Spinner /> : <AddUpdateFridgeForm pageTitle={t('Fridge adding')} {...props} />;
};

export default AddFridge;
