import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';

const InnerLocationInputField = ({ className, style, latitude, longitude, ...props }) => {
  const { t } = useTranslation();
  let styles = {};
  styles.fontSize = 14;
  const innerProps = { style: styles };

  return (
    <React.Fragment>
      <div className={className} style={style}>
        <TextField
          style={{ width: '100%' }}
          inputProps={innerProps}
          variant="outlined"
          label={t('Latitude')}
          {...latitude.input}
          {...props}
        />
        <TextField
          style={{ width: '100%' }}
          inputProps={innerProps}
          variant="outlined"
          label={t('Longitude')}
          {...longitude.input}
          {...props}
        />
      </div>
      {[latitude.meta, longitude.meta].some((meta) => meta.touched && meta.error) && (
        <div className={className}>
          {[latitude.meta, longitude.meta].map((meta, i) => (
            <div key={i}>
              <span style={{ color: 'red' }}>{meta.error}</span>
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default InnerLocationInputField;
