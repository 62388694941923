import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import Spinner from '../../common/ProgressIndicators/Spinner';
import UsersTable from './UsersTable';
import paginationConstants from '../../helpers/paginationConstants';
import FilterUsersFormContainer from './FilterUsers/FilterUsersFormContainer';
import { getQueryString } from '../../helpers/queryHelper';
import { changeSortOrder, getSortOrderObjectFromString } from '../../helpers/Sort/SortOrderHelper';
import { capitalize } from '../../helpers/stringsHelper';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '96%',
    minWidth: '1564px',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '20px',
    marginBottom: '10px',
    backgroundColor: theme.palette.white,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
  },
  gridItem: {
    margin: '10px',
  },
  title: {
    color: theme.palette.primary.dark,
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
    minWidth: '1564px',
    backgroundColor: theme.palette.white,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const UserList = (props) => {
  const {
    classes,
    users,
    isLoading,
    usersCount,
    currentPage,
    searchString,
    startDate,
    endDate,
    selectedFridgesIds,
    getUsers,
    sortOrder,
    t,
    banUser,
    unbanUser,
  } = props;

  const [currentSortOrder, setCurrentSortOrder] = useState({
    name: 'fullName',
    direction: 'asc',
  });

  useEffect(() => {
    if (sortOrder) {
      const sortOrderObject = getSortOrderObjectFromString(sortOrder);
      setCurrentSortOrder(sortOrderObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrder, setCurrentSortOrder]);

  useEffect(() => {
    getUsers(
      currentPage,
      paginationConstants.itemsPerPage,
      searchString,
      startDate,
      endDate,
      selectedFridgesIds,
      sortOrder,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    searchString,
    startDate,
    endDate,
    sortOrder,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    selectedFridgesIds.toString(),
    getUsers,
  ]);

  const handlePageChange = (event, pageNumber) => {
    const queryString = getQueryString({
      page: pageNumber,
      startDate: startDate,
      endDate: endDate,
      fridges: selectedFridgesIds,
      searchString: searchString,
      sortOrder: sortOrder,
    });
    props.history.push({
      pathname: props.history.location.pathname,
      search: queryString,
    });
  };

  const toggleSortOrder = (sortOrder) => {
    const newSortOrder = changeSortOrder(currentSortOrder, sortOrder);
    const newSortOrderString = newSortOrder.name + capitalize(newSortOrder.direction);

    setCurrentSortOrder(newSortOrder);

    const queryString = getQueryString({
      page: currentPage,
      startDate: startDate,
      endDate: endDate,
      fridges: selectedFridgesIds,
      searchString: searchString,
      sortOrder: newSortOrderString,
    });
    props.history.push({
      pathname: props.history.location.pathname,
      search: queryString,
    });
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container direction="row" spacing={0} className={classes.container}>
          <Grid item className={classes.gridItem}>
            <Typography className={classes.title} variant={'h6'}>
              {t('List of users')}
            </Typography>
          </Grid>
          <Grid item className={`${classes.gridItem}`}>
            <FilterUsersFormContainer
              endDate={endDate}
              startDate={startDate}
              fridgesIds={selectedFridgesIds}
              searchString={searchString}
            />
          </Grid>
        </Grid>
        {isLoading ? (
          <Spinner />
        ) : (
          <UsersTable
            activePage={currentPage}
            handlePageChange={handlePageChange}
            users={users}
            usersCount={usersCount}
            toggleSortOrder={toggleSortOrder}
            currentSortOrder={currentSortOrder}
            searchString={searchString}
            selectedFridgesIds={selectedFridgesIds}
            banUser={banUser}
            unbanUser={unbanUser}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default compose(withTranslation(), withStyles(styles), withRouter)(UserList);
