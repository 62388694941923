import FRIDGES_CONSTANTS from './fridgesConstants';
import axios from 'axios';
import { getErrorsForNotificationPopup } from '../../../components/helpers/getErrorsForNotificationPopup';
import { getQueryString } from '../../../components/helpers/queryHelper';

export const getFridges = (pageNumber, fridgesPerPage, fridgesIds) => (dispatch) => {
  const fridgesQueryString = getQueryString({ fridges: fridgesIds });

  dispatch({
    type: FRIDGES_CONSTANTS.FRIDGES_PENDING,
    payload: true,
  });
  return axios
    .get(`/fridges?pageNumber=${pageNumber}&itemsPerPage=${fridgesPerPage}&${fridgesQueryString}`)
    .then((response) =>
      dispatch({
        type: FRIDGES_CONSTANTS.GET_FRIDGES,
        payload: {
          fridges: response.data.items,
          fridgesCount: response.data.totalItems,
          currentPage: response.data.currentPage,
        },
      }),
    );
};

export const getFridgeById = (fridgeId) => (dispatch) => {
  dispatch({
    type: FRIDGES_CONSTANTS.FRIDGES_PENDING,
    payload: true,
  });
  return axios
    .get(`/fridges/${fridgeId}`)
    .then((response) =>
      dispatch({
        type: FRIDGES_CONSTANTS.GET_FRIDGE_BY_ID,
        payload: {
          fridge: response.data,
          isLoading: false,
        },
      }),
    )
    .catch(() => {
      dispatch({
        type: FRIDGES_CONSTANTS.FRIDGES_PENDING,
        payload: false,
      });
    });
};

export const addFridge =
  (
    number,
    name,
    address,
    description,
    pricingCoefficient,
    externalId,
    hasLock,
    controllerDeviceAddress,
    lockingDeviceNumber,
    location,
    isHidden,
  ) =>
  (dispatch) => {
    dispatch({
      type: FRIDGES_CONSTANTS.FRIDGES_PENDING,
      payload: true,
    });

    return axios
      .post('/fridges', {
        number: number,
        name: name,
        address: address,
        description: description || null,
        pricingCoefficient: pricingCoefficient,
        externalId: externalId || null,
        hasLock: hasLock || false,
        controllerDeviceAddress: hasLock ? controllerDeviceAddress : null,
        lockingDeviceNumber: hasLock ? lockingDeviceNumber.value : null,
        isHidden: isHidden,
        location: location || null,
      })
      .then((response) => {
        dispatch({
          type: FRIDGES_CONSTANTS.FRIDGES_PENDING,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: FRIDGES_CONSTANTS.FRIDGES_PENDING,
          payload: false,
        });

        const errorMessage = getErrorsForNotificationPopup(error.response.data.errors);
        throw errorMessage;
      });
  };

export const updateFridge =
  (
    number,
    id,
    name,
    address,
    description,
    pricingCoefficient,
    externalId,
    hasLock,
    controllerDeviceAddress,
    lockingDeviceNumber,
    location,
    isHidden,
  ) =>
  (dispatch) => {
    dispatch({
      type: FRIDGES_CONSTANTS.FRIDGES_PENDING,
      payload: true,
    });

    return axios
      .put('/fridges', {
        number: number,
        name: name,
        address: address,
        description: description || null,
        pricingCoefficient: pricingCoefficient,
        id: id,
        externalId: externalId || null,
        hasLock: hasLock || false,
        controllerDeviceAddress: hasLock ? controllerDeviceAddress : null,
        lockingDeviceNumber: hasLock ? lockingDeviceNumber.value : null,
        isHidden,
        location: location || null,
      })
      .then((response) => {
        dispatch({
          type: FRIDGES_CONSTANTS.FRIDGES_PENDING,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: FRIDGES_CONSTANTS.FRIDGES_PENDING,
          payload: false,
        });

        const errorMessage = getErrorsForNotificationPopup(error.response.data.errors);
        throw errorMessage;
      });
  };

export const deleteFridge = (id) => (dispatch) => {
  dispatch({
    type: FRIDGES_CONSTANTS.FRIDGES_PENDING,
    payload: true,
  });
  return axios.delete(`/fridges/${id}`).then(() => {
    dispatch({
      type: FRIDGES_CONSTANTS.DELETE_FRIDGE,
      payload: {
        id,
        isLoading: false,
      },
    });
  });
};

export const getFridgeUnlockHistory = (fridgeId, pageNumber, itemsPerPage, onlySuspiciousUnlocks) => (dispatch) => {
  dispatch({
    type: FRIDGES_CONSTANTS.FRIDGES_PENDING,
    payload: true,
  });

  return axios
    .get(
      `/fridges/unlocks?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}&onlySuspiciousUnlocks=${onlySuspiciousUnlocks}&fridgeId=${fridgeId}`,
    )
    .then((res) =>
      dispatch({
        type: FRIDGES_CONSTANTS.GET_FRIDGE_UNLOCK_HISTORY,
        payload: {
          unlockHistory: res.data,
          isLoading: false,
        },
      }),
    );
};

export const getFridgeHealthChecks = (fridgeId, pageNumber, itemsPerPage) => (dispatch) => {
  dispatch({
    type: FRIDGES_CONSTANTS.FRIDGES_PENDING,
    payload: true,
  });

  return axios
    .get(`/fridges/${fridgeId}/healthChecks?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`)
    .then((res) =>
      dispatch({
        type: FRIDGES_CONSTANTS.GET_FRIDGE_HEALTH_CHECKS,
        payload: {
          healthChecks: res.data,
          isLoading: false,
        },
      }),
    );
};
