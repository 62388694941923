import React, { useEffect } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import FridgesTable from './FridgesTable';
import paginationConstants from '../../helpers/paginationConstants';
import Spinner from '../../common/ProgressIndicators/Spinner';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '96%',
    minWidth: 700,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '20px',
    marginBottom: '10px',
    backgroundColor: theme.palette.white,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
  },
  gridItem: {
    margin: '10px',
  },
  addFridgeButton: {
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
    margin: '0 5px',
  },
  title: {
    color: theme.palette.primary.dark,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
  },
});

const FridgesList = (props) => {
  const { classes, fridges, deleteFridge, isLoading, currentPage, fridgesCount, t, getFridges } = props;

  useEffect(() => {
    getFridges(currentPage, paginationConstants.itemsPerPage);
  }, [getFridges, currentPage]);

  const handlePageChange = (event, pageNumber) => {
    const queryString = `page=${pageNumber}`;

    props.history.push({
      pathname: props.history.location.pathname,
      search: queryString,
    });
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container direction="row" spacing={0} className={classes.container}>
          <Grid item className={classes.gridItem}>
            <Typography className={classes.title} variant={'h6'}>
              {t('List of fridges')}
            </Typography>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Button
              startIcon={<LockOpenIcon />}
              variant={'contained'}
              className={classes.addFridgeButton}
              onClick={() => props.history.push('/fridges/unlocks')}
            >
              {t('Unlocks history')}
            </Button>
            <Button
              startIcon={<AddIcon />}
              variant={'contained'}
              className={classes.addFridgeButton}
              onClick={() => props.history.push('/fridges/add')}
            >
              {t('Add')}
            </Button>
          </Grid>
        </Grid>
        {isLoading ? (
          <Spinner />
        ) : (
          <FridgesTable
            fridges={fridges}
            deleteFridge={deleteFridge}
            activePage={currentPage}
            handlePageChange={handlePageChange}
            fridgesCount={fridgesCount}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default compose(withTranslation(), withStyles(styles), withRouter)(FridgesList);
