import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import UpdateProductForm from './UpdateProductForm';
import { getRouteParamFromUrl } from '../../helpers/getParamsFromUrl';
import * as productsActions from './../../../redux/modules/products/productsActions';
import { validateAddUpdateProductForm } from '../../validators/formValidators';
import { showNotification } from '../../helpers/notificationHelper';
import notificationTypes from '../../helpers/notificationTypes';
import i18n from 'i18next';

const selector = formValueSelector('updateProductForm');

const mapStateToProps = (state, ownProps) => ({
  barcodeValue: selector(state, 'barcodeValue'),
  name: selector(state, 'name'),
  imageUrl: selector(state, 'imageUrl'),
  price: selector(state, 'price'),
  proteins: selector(state, 'proteins'),
  fats: selector(state, 'fats'),
  carbohydrates: selector(state, 'carbohydrates'),
  kiloCalories: selector(state, 'kiloCalories'),
  ingredients: selector(state, 'ingredients'),
  notes: selector(state, 'notes'),
  category: selector(state, 'category'),
  productId: getRouteParamFromUrl(ownProps, 'id'),
  product: state.products.selectedProduct,
  initialValues: {
    ...state.products.selectedProduct,
    category: {
      value: state.products.selectedProduct.categoryId,
      label: state.products.selectedProduct.categoryName,
    },
  },
  categories: state.products.productCategories,
  isLoading: state.products.isLoading,
});

const mapDispatchToProps = {
  getProduct: productsActions.getProduct,
  getProductCategories: productsActions.getProductCategories,
  updateProduct: productsActions.updateProduct,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'updateProductForm',
    validate: validateAddUpdateProductForm,
    onSubmit: (payload, dispatch, props) => {
      props
        .updateProduct(payload, props.product.id)
        .then(() => props.history.push('/products'))
        .catch((error) => {
          showNotification(notificationTypes.error, error, i18n.t('Changes were not saved.'));
        });
    },
    enableReinitialize: true,
  }),
)(UpdateProductForm);
