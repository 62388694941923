import PRODUCTS_CONSTANTS from './productsConstants';

const defaultState = {
  isLoading: false,
  products: [],
  productCategories: [],
  detailedCategories: [],
  selectedProduct: {},
  selectedCategory: {},
  currentPage: 1,
  productsCount: 1,
  categoriesCount: 1,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PRODUCTS_CONSTANTS.PRODUCTS_PENDING:
      return handleRequestPending(state, action.payload);

    case PRODUCTS_CONSTANTS.GET_PRODUCTS:
      return handleGetProducts(
        state,
        action.payload.products,
        action.payload.currentPage,
        action.payload.productsCount,
      );

    case PRODUCTS_CONSTANTS.GET_DETAILED_CATEGORIES:
      return handleGetDetailedCategories(
        state,
        action.payload.productCategories,
        action.payload.currentPage,
        action.payload.categoriesCount,
      );

    case PRODUCTS_CONSTANTS.GET_CATEGORY_BY_ID:
      return handleGetSelectedCategory(state, action.payload);

    case PRODUCTS_CONSTANTS.GET_PRODUCT_BY_ID:
      return handleGetSelectedProduct(state, action.payload);

    case PRODUCTS_CONSTANTS.GET_PRODUCT_CATEGORIES:
      return handleGetProductCategories(state, action.payload);

    case PRODUCTS_CONSTANTS.GET_PRODUCTS_RATING:
      return handleGetProductsRating(
        state,
        action.payload.products,
        action.payload.currentPage,
        action.payload.productsCount,
      );

    case PRODUCTS_CONSTANTS.GET_PRODUCT_CUSTOM_PRICES:
      return handleGetProductCustomPrices(
        state,
        action.payload.fridgePrices,
        action.payload.currentPage,
        action.payload.fridgePricesCount,
      );

    case PRODUCTS_CONSTANTS.GET_PRODUCT_CUSTOM_PRICE_IN_FRIDGE:
      return handleGetProductCustomPriceInFridge(state, action.payload.price);

    case PRODUCTS_CONSTANTS.DELETE_PRODUCT_CUSTOM_PRICE_IN_FRIDGE:
      return handleDeleteProductCustomPriceInFridge(state, action.payload);

    default:
      return state;
  }
};

function handleRequestPending(state, pending) {
  return {
    ...state,
    isLoading: pending,
  };
}

function handleGetProducts(state, products, currentPage, totalItems) {
  return {
    ...state,
    products: products,
    currentPage: currentPage,
    productsCount: totalItems,
    isLoading: false,
  };
}

function handleGetProductCategories(state, categories) {
  return {
    ...state,
    productCategories: categories.productCategories,
    isLoading: false,
  };
}

function handleGetSelectedProduct(state, product) {
  return {
    ...state,
    selectedProduct: product.product,
    isLoading: false,
  };
}

function handleGetSelectedCategory(state, category) {
  return {
    ...state,
    selectedCategory: category.productCategory,
    isLoading: false,
  };
}

function handleGetDetailedCategories(state, categories, currentPage, totalItems) {
  return {
    ...state,
    detailedCategories: categories,
    currentPage: currentPage,
    categoriesCount: totalItems,
    isLoading: false,
  };
}

function handleGetProductsRating(state, products, currentPage, totalItems) {
  return {
    ...state,
    products: products,
    currentPage: currentPage,
    productsCount: totalItems,
    isLoading: false,
  };
}

function handleGetProductCustomPrices(state, fridgePrices, currentPage, totalItems) {
  return {
    ...state,
    fridgePrices: fridgePrices,
    currentPage: currentPage,
    fridgePricesCount: totalItems,
    isLoading: false,
  };
}

function handleGetProductCustomPriceInFridge(state, price) {
  return {
    ...state,
    fridgePrice: price,
    isLoading: false,
  };
}

function handleDeleteProductCustomPriceInFridge(state, payload) {
  return {
    ...state,
    isLoading: payload.isLoading,
    fridgePrices: state.fridgePrices.filter((fpp) => fpp.fridge.id !== payload.fridgeId),
  };
}
