import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { SummaryBlockStyles } from '../../../styles/SummaryBlockStyles';
import { useTranslation } from 'react-i18next';

const OrdersSummary = (props) => {
  const {
    classes,
    totalOrders,
    totalSum,
    getOrdersSummary,
    startDate,
    endDate,
    fridgesIds,
    totalPaidWithBonuses,
    totalPaidWithMoney,
    orderStatus,
    userId,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    getOrdersSummary(startDate, endDate, fridgesIds, orderStatus, userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, fridgesIds.toString(), orderStatus, userId, getOrdersSummary]);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container direction="row" spacint={0} className={classes.container}>
          <Typography className={classes.summaryText}>
            {t('Number of orders (orders summary)')} {totalOrders}
          </Typography>
          <Typography className={classes.summaryText}>
            {t('Total paid: ')} {totalSum}
          </Typography>
          {totalPaidWithBonuses > 0 && (
            <>
              <Typography className={classes.summaryText}>
                {t('Total paid with money: ')}
                {totalPaidWithMoney}
              </Typography>
              <Typography className={classes.summaryText}>
                {t('Total paid with bonuses: ')}
                {totalPaidWithBonuses}
              </Typography>
            </>
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default withStyles(SummaryBlockStyles)(OrdersSummary);
