import React from 'react';
import { TableRow, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Pagination from '@material-ui/lab/Pagination';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import paginationConstants from '../../helpers/paginationConstants';
import commonStyles from '../../helpers/commonStyles';
import OrdersHistoryTableRow from './OrdersHistoryTableRow';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  table: {
    minWidth: '1300px',
  },
  headerRow: {
    backgroundColor: theme.palette.whiteblue,
  },
  tableContent: {
    backgroundColor: theme.palette.white,
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableHeader: {
    borderTop: commonStyles.tableHeaderTopBorder,
  },
});

const OrdersHistoryTable = (props) => {
  const { classes, orders, handlePageChange, activePage, ordersCount, startDate, fridgesIds, orderStatus, userId } =
    props;
  const { t } = useTranslation();

  const pagesCount = Math.ceil(ordersCount / paginationConstants.itemsPerPage);

  const renderOrdersHistoryTable = () => {
    if (orders.length !== 0) {
      return orders.map((order) => <OrdersHistoryTableRow key={order.id} order={order} />);
    } else if (startDate || (fridgesIds && fridgesIds.length) || orderStatus || userId) {
      return (
        <TableRow className={classes.tableContent}>
          <TableCell align={'center'} colSpan={13}>
            {t('Could not find any order for your request.')}
          </TableCell>
        </TableRow>
      );
    }
  };

  return (
    <React.Fragment>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell className={classes.tableHeader} />
            <TableCell className={classes.tableHeader}>{t("Order's №")}</TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Date')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Fridge')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Products in order')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Paid')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Paid with bonuses')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Total price')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Production cost')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Status')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Customer')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t("Customer's phone number")}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t("Customer's registration date")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderOrdersHistoryTable()}</TableBody>
      </Table>
      <Pagination
        className={classes.paginationContainer}
        size={'large'}
        variant="outlined"
        shape="rounded"
        count={pagesCount}
        page={activePage}
        onChange={handlePageChange}
        color="primary"
      />
    </React.Fragment>
  );
};

export default compose(withRouter, withStyles(styles))(OrdersHistoryTable);
