import { store } from 'react-notifications-component';

export const showNotification = (type, message, title) => {
  store.addNotification({
    message: message,
    title: title,
    type: type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};
