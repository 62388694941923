import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import * as productsActions from './../../../../redux/modules/products/productsActions';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import FilterProductsForm from './FilterProductsForm';
import { getSortOrder } from '../../../helpers/queryHelper';
import { getProductsQueryStringWithSorting } from '../../../helpers/Products/productsQueryHelper';

const mapStateToProps = (state, ownProps) => ({
  sortOrder: getSortOrder(ownProps),
  initialValues: {
    title: ownProps.searchString,
    selectedCategories: ownProps.categories
      .filter((c) => ownProps.categoriesIds.includes(c.id))
      .map((c) => ({
        label: c.name,
        value: c.id,
      })),
    selectedFridge: state.fridges.fridges
      .filter((f) => ownProps.selectedFridgeId === f.id)
      .map((f) => ({ value: f.id, label: `#${f.number}, ${f.name}` }))[0],
    showProductsWithCustomPriceOnly: ownProps.selectedFridge && ownProps.showProductsWithCustomPriceOnly,
  },
});

const mapDispatchToProps = {
  getProducts: productsActions.getProducts,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'filterProductsForm',
    onSubmit: (payload, dispatch, props) => {
      const queryString = getProductsQueryStringWithSorting(
        1,
        payload.title,
        payload.selectedCategories || [],
        props.sortOrder,
        payload.selectedFridge?.value,
        payload.showProductsWithCustomPriceOnly && (payload.selectedFridge ? true : false),
      );
      props.history.push({
        pathname: props.history.location.pathname,
        search: queryString,
      });
    },
    enableReinitialize: true,
  }),
)(FilterProductsForm);
