import React, { useRef, useState } from 'react';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import WarningModal from '../../../components/Modals/WarningModal/WarningModal';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  input: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
  actionButton: {
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
    lineHeight: '1.5',
    height: '36px',
    marginLeft: '30px',
  },
});

const XmlFilesUploader = (props) => {
  const { classes, importProductsFromXml, t } = props;
  const inputEl = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [duplicatesList, setDuplicatesList] = useState(null);

  const handleChange = async (e) => {
    const value = e.target.files[0];
    if (value) {
      const data = new FormData();
      data.append('xmlFile', value);
      const result = await importProductsFromXml(data);
      if (result?.duplicates.length > 0) {
        handleOpenModal(result.duplicates);
      }
    }
  };

  const handleOpenModal = (duplicates) => {
    setDuplicatesList(duplicates);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        startIcon={<AddIcon />}
        variant={'contained'}
        className={classes.actionButton}
        onClick={() => inputEl.current.click()}
      >
        {t('Import')}
      </Button>
      <input ref={inputEl} className={classes.input} type={'file'} id="file" onChange={handleChange} />
      <WarningModal
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        modalTitle={t('The following products have not been imported. Check for duplication of barcodes:')}
        modalDescription={duplicatesList?.map((product) => `- ${product.name}\n`)}
      />
    </React.Fragment>
  );
};

export default compose(withTranslation(), withStyles(styles))(XmlFilesUploader);
