import React from 'react';
import { withStyles } from '@material-ui/core/es/styles';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { FormControlLabel } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { Field } from 'redux-form';
import FridgesSelectFieldWithPagination from '../../../fields/SelectWithPagination/FridgesSelectFieldWithPagination';

const styles = (theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.white,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  filterButton: {
    marginLeft: '10px',
  },
  filterContainer: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
});

const FilterUnlockHistoryForm = (props) => {
  const { classes, handleSubmit, fridgeId } = props;
  const { t } = useTranslation();

  return (
    <form className={classes.tableHeader} onSubmit={handleSubmit}>
      <div className={classes.container}>
        <Field
          name="onlySuspiciousUnlocks"
          label="Show Only Suspicious Unlocks"
          component={({ input, label }) => {
            return (
              <FormControlLabel
                labelPlacement="end"
                control={<Switch checked={input.value} onChange={input.onChange} color="primary" />}
                label={t(label)}
              />
            );
          }}
        />
        <div className={classes.filterContainer}>
          <FridgesSelectFieldWithPagination
            className={classes.input}
            name="fridge"
            placeholder={t('Fridge not selected')}
            fridgesIds={[fridgeId]}
          />
        </div>
        <Button className={classes.filterButton} variant={'outlined'} type="submit">
          {t('Apply filters')}
        </Button>
      </div>
    </form>
  );
};

export default withStyles(styles)(FilterUnlockHistoryForm);
