import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    width: '400px',
    margin: 'auto',
  },
  headline: {
    color: theme.palette.grey.dark,
    fontWeight: 'bold',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    margin: '0 10px',
  },
  deleteButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
});

const ConfirmationModal = (props) => {
  const { isOpen, handleSubmit, handleClose, modalTitle, confirmButtonName, classes } = props;
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={handleClose} className={classes.root}>
      <DialogTitle id="form-dialog-title">
        <div className={classes.headline}>{modalTitle}</div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.buttonWrapper}>
          <Button variant={'contained'} onClick={handleClose} data-cy="deny-btn" className={classes.button}>
            {t('Cancel')}
          </Button>
          <Button
            color={'secondary'}
            variant="contained"
            onClick={handleSubmit}
            data-cy="confirm-btn"
            className={`${classes.button} ${classes.deleteButton}`}
          >
            {confirmButtonName || t('Confirm')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(ConfirmationModal);
