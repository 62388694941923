import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.white,
    left: 0,
    top: 0,
    zIndex: 100,
  },
  progressWrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const FullScreenLoader = (props) => {
  return (
    <div className={props.classes.root}>
      <div className={props.classes.progressWrapper}>
        <CircularProgress size={200} thickness={1} color="primary" />
      </div>
    </div>
  );
};

export default withStyles(styles)(FullScreenLoader);
