import React from 'react';
import Spinner from '../../common/ProgressIndicators/Spinner';
import AddUpdateProductCategoryForm from '../../common/Forms/AddUpdateProductCategoryForm';
import { useTranslation } from 'react-i18next';

const AddProductCategory = (props) => {
  const { isLoading } = props;
  const { t } = useTranslation();
  return isLoading ? <Spinner /> : <AddUpdateProductCategoryForm pageTitle={t('Category adding')} {...props} />;
};

export default AddProductCategory;
