import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

const InnerSelectFieldWithPagination = ({
  input,
  label,
  meta: { touched, error },
  required,
  initialOptions,
  ...custom
}) => {
  return (
    <React.Fragment>
      <AsyncPaginate
        value={input.value}
        required={required}
        clearable={custom.clearable}
        backspaceRemoves={custom.backspaceRemoves}
        searchable={custom.searchable}
        deleteRemoves={custom.deleteRemoves}
        onChange={(value) => input.onChange(value)}
        loadOptionsOnMenuOpen={true}
        additional={{ page: 1, initialOptions: initialOptions || [] }}
        {...custom}
      />
      {touched && error && (
        <span style={{ color: 'red' }} {...custom}>
          {error}
        </span>
      )}
    </React.Fragment>
  );
};

export default InnerSelectFieldWithPagination;
