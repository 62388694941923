import React, { useEffect } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { SummaryBlockStyles } from '../../../styles/SummaryBlockStyles';

const UsersSummary = (props) => {
  const { classes, usersSummary, getUsersSummary, t } = props;

  useEffect(() => {
    getUsersSummary();
  }, [getUsersSummary]);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.container}>
          <Typography className={classes.summaryText}>
            {t('Total number of users: ')}
            {usersSummary?.numberOfUsers}
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
};

export default compose(withTranslation(), withStyles(SummaryBlockStyles))(UsersSummary);
