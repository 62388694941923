import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import FridgesListPage from './components/pages/FridgesList/FridgesListPage';
import AddFridgePage from './components/pages/AddFridge/AddFridgePage';
import LoginPage from './components/pages/Login/LoginPage';
import WithAuthorization from './components/hocs/withAuthorization';
import ProductsImportPage from './components/pages/Import/ImportPage';
import ProductsListPage from './components/pages/ProductsList/ProductsListPage';
import AddProductPage from './components/pages/AddProduct/AddProductPage';
import UpdateProductPage from './components/pages/UpdateProduct/UpdateProductPage';
import ProductDetailsPage from './components/pages/ProductDetails/ProductDetailsPage';
import UpdateFridgePage from './components/pages/UpdateFridge/UpdateFridgePage';
import ProductCategoriesListPage from './components/pages/ProductCategoriesList/ProductCategoriesListPage';
import AddProductCategoryPage from './components/pages/AddProductCategory/AddProductCategoryPage';
import UpdateProductCategoryPage from './components/pages/UpdateProductCategory/UpdateProductCategoryPage';
import UnlockHistoryPage from './components/pages/FridgeUnlockHistory/UnlockHistoryPage';
import UsersListPage from './components/pages/Users/UsersListPage';
import OrdersHistoryListPage from './components/pages/OrdersHistoryList/OrdersHistoryListPage';
import HealthChecksPage from './components/pages/FridgeHealthChecks/HealthChecksPage';
import ChangePasswordPage from './components/pages/ChangePassword/ChangePasswordPage';
import ProductsRatingPage from './components/pages/ProductsRating/ProductsRatingPage';
import ProductCustomPricesPage from './components/pages/ProductCustomPrices/ProductCustomPricesPage';
import SetProductCustomPricePage from './components/pages/SetProductCustomPrice/SetProductCustomPricePage';

export default function Routes(props) {
  return (
    <BrowserRouter>
      <React.Fragment>
        <Switch>
          <Route exact path="/fridges/add" component={WithAuthorization(AddFridgePage)} />
          <Route exact path="/fridges" component={WithAuthorization(FridgesListPage)} />
          <Route exact path="/fridges/:id/edit" component={WithAuthorization(UpdateFridgePage)} />
          <Route exact path="/fridges/unlocks" component={WithAuthorization(UnlockHistoryPage)} />
          <Route exact path="/fridges/:id/healthchecks" component={WithAuthorization(HealthChecksPage)} />
          <Route exact path="/products/import" component={WithAuthorization(ProductsImportPage)} />
          <Route exact path="/products" component={WithAuthorization(ProductsListPage)} />
          <Route exact path="/products/:id/info" component={WithAuthorization(ProductDetailsPage)} />
          <Route exact path="/products/add" component={WithAuthorization(AddProductPage)} />
          <Route exact path="/products/:id/edit" component={WithAuthorization(UpdateProductPage)} />
          <Route exact path="/products/categories" component={WithAuthorization(ProductCategoriesListPage)} />
          <Route exact path="/products/categories/add" component={WithAuthorization(AddProductCategoryPage)} />
          <Route exact path="/products/categories/:id/edit" component={WithAuthorization(UpdateProductCategoryPage)} />
          <Route exact path="/products/rating" component={WithAuthorization(ProductsRatingPage)} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/users" component={WithAuthorization(UsersListPage)} />
          <Route exact path="/orders" component={WithAuthorization(OrdersHistoryListPage)} />
          <Route exact path="/account/changepassword" component={WithAuthorization(ChangePasswordPage)} />
          <Route exact path="/products/:id/customPrices" component={WithAuthorization(ProductCustomPricesPage)} />
          <Route exact path="/products/:id/customPrices/add" component={WithAuthorization(SetProductCustomPricePage)} />
          <Redirect from="*" exact to="/fridges" />
        </Switch>
      </React.Fragment>
    </BrowserRouter>
  );
}
