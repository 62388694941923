import { reducer as formReducer } from 'redux-form';
import fridges from './../modules/fridges/fridgesReducers';
import products from './../modules/products/productsReducers';
import login from './../modules/login/loginReducers';
import users from './../modules/users/usersReducers';
import orders from './../modules/orders/ordersReducers';
import account from './../modules/account/accountReducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  fridges,
  products,
  login,
  users,
  orders,
  account,
  form: formReducer,
});

export default rootReducer;
