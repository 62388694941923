import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { getRouteParamFromUrl } from '../../helpers/getParamsFromUrl';
import * as productsActions from './../../../redux/modules/products/productsActions';
import ProductDetails from './ProductDetails';

const mapStateToProps = (state, ownProps) => ({
  productId: getRouteParamFromUrl(ownProps, 'id'),
  product: state.products.selectedProduct,
  isLoading: state.products.isLoading,
});

const mapDispatchToProps = {
  getProduct: productsActions.getProduct,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProductDetails);
