import React from 'react';
import { TableRow, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Pagination from '@material-ui/lab/Pagination';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import commonStyles from '../../helpers/commonStyles';
import paginationConstants from '../../helpers/paginationConstants';
import { toLongDateTime } from '../../helpers/dateFormatter';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  table: {
    minWidth: '700px',
  },
  headerRow: {
    backgroundColor: theme.palette.whiteblue,
  },
  tableContent: {
    backgroundColor: theme.palette.white,
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableHeader: {
    borderTop: commonStyles.tableHeaderTopBorder,
  },
  successfulStatus: {
    color: theme.palette.primary.dark,
  },
  failedStatus: {
    color: theme.palette.red,
  },
});

const HealthChecksTable = (props) => {
  const { classes, healthChecks, handlePageChange, activePage, healthChecksCount } = props;
  const pagesCount = Math.ceil(healthChecksCount / paginationConstants.itemsPerPage);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell className={classes.tableHeader}>{t('Date')} </TableCell>
            <TableCell className={classes.tableHeader} align="center">
              {t('Controller device address')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="center">
              {t('Status')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {healthChecks.length !== 0 ? (
            healthChecks.map((healthCheck) => (
              <TableRow key={healthCheck.id} className={classes.tableContent}>
                <TableCell component="th" scope="row">
                  {toLongDateTime(healthCheck.date)}
                </TableCell>
                <TableCell align="center">{healthCheck.controllerDeviceAddress}</TableCell>
                <TableCell align="center">
                  <Typography className={healthCheck.status === 'ОК' ? classes.successfulStatus : classes.failedStatus}>
                    {healthCheck.status}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow className={classes.tableContent}>
              <TableCell align="center" colSpan={3}>
                {t('There were no health checks carried out for this fridge.')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        className={classes.paginationContainer}
        size={'large'}
        variant="outlined"
        shape="rounded"
        count={pagesCount}
        page={activePage}
        onChange={handlePageChange}
        color="primary"
      />
    </React.Fragment>
  );
};

export default compose(withRouter, withStyles(styles))(HealthChecksTable);
