export const changeSortOrder = (currentSortOrder, newSortOrder) => {
  let direction;
  if (currentSortOrder.name === newSortOrder) {
    direction = currentSortOrder.direction === 'asc' ? 'desc' : 'asc';
  } else {
    direction = 'asc';
  }

  return { name: newSortOrder, direction: direction };
};

export const getSortOrderObjectFromString = (sortOrderString) => {
  const indexOfAsc = sortOrderString.indexOf('Asc');
  const indexOfDesc = sortOrderString.indexOf('Desc');
  if (indexOfAsc < 0 && indexOfDesc < 0) {
    throw new Error(
      `Invalid argument '${sortOrderString}' for sort order.\n Sort order string must contain direction (Asc | Desc) value`,
    );
  }

  const directionIndex = indexOfAsc > 0 ? indexOfAsc : indexOfDesc;
  return {
    name: sortOrderString.substring(0, directionIndex),
    direction: sortOrderString.substring(directionIndex, sortOrderString.length).toLowerCase(),
  };
};
