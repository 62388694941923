import React from 'react';
import { TableRow, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Pagination from '@material-ui/lab/Pagination';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import paginationConstants from '../../helpers/paginationConstants';
import commonStyles from '../../helpers/commonStyles';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  table: {
    minWidth: '700px',
  },
  headerRow: {
    backgroundColor: theme.palette.whiteblue,
  },
  tableContent: {
    backgroundColor: theme.palette.white,
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableHeader: {
    borderTop: commonStyles.tableHeaderTopBorder,
  },
});

class ProductCategoriesTable extends React.Component {
  render() {
    const { classes, categories, handlePageChange, activePage, productCategoriesCount, searchString, t } = this.props;
    const pagesCount = Math.ceil(productCategoriesCount / paginationConstants.itemsPerPage);

    const editCategory = (categoryId) => {
      this.props.history.push(`/products/categories/${categoryId}/edit`);
    };

    return (
      <React.Fragment>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.headerRow}>
              <TableCell colSpan={2} className={classes.tableHeader}>
                {t('Name')}
              </TableCell>
              <TableCell colSpan={2} className={classes.tableHeader} align="right">
                {t('Number of products')}
              </TableCell>
              <TableCell colSpan={2} className={classes.tableHeader} align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.length !== 0
              ? categories.map((category) => (
                  <TableRow key={category.id} className={classes.tableContent}>
                    <TableCell colSpan={2} component="th" scope="row">
                      {category.name}
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      {category.productsCount}
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      <Button variant={'outlined'} onClick={() => editCategory(category.id)} startIcon={<EditIcon />}>
                        {t('Edit')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : searchString && (
                  <TableRow className={classes.tableContent}>
                    <TableCell align={'center'} colSpan={6}>
                      {t('Could not find any category for your request.')}
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
        <Pagination
          className={classes.paginationContainer}
          size={'large'}
          variant="outlined"
          shape="rounded"
          count={pagesCount}
          page={activePage}
          onChange={handlePageChange}
          color="primary"
        />
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), withRouter, withStyles(styles))(ProductCategoriesTable);
