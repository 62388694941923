import React from 'react';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import Spinner from '../../common/ProgressIndicators/Spinner';
import AddIcon from '@material-ui/icons/Add';
import paginationConstants from '../../helpers/paginationConstants';
import SearchProductCategoryFormContainer from './SearchProductCategory/SearchProductCategoryFormContainer';
import ProductCategoriesTable from './ProductCategoriesTable';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '96%',
    minWidth: 700,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '20px',
    marginBottom: '10px',
    backgroundColor: theme.palette.white,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
  },
  gridItem: {
    margin: '10px',
  },
  addCategoryButton: {
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
    marginLeft: '20px',
    lineHeight: '1.5',
    height: '36px',
  },
  title: {
    color: theme.palette.primary.dark,
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
    backgroundColor: theme.palette.white,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

class ProductCategoriesList extends React.Component {
  componentDidMount() {
    this.props.getProductCategories(this.props.currentPage, paginationConstants.itemsPerPage, this.props.searchString);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentPage !== prevProps.currentPage || this.props.searchString !== prevProps.searchString) {
      this.props.getProductCategories(
        this.props.currentPage,
        paginationConstants.itemsPerPage,
        this.props.searchString,
      );
    }
  }

  handlePageChange = (event, pageNumber) => {
    const queryString = this.props.searchString
      ? `page=${pageNumber}&searchString=${this.props.searchString}`
      : `page=${pageNumber}`;
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: queryString,
    });
  };

  render() {
    const { classes, productCategories, isLoading, productCategoriesCount, currentPage, searchString, t } = this.props;
    return (
      <React.Fragment>
        <div className={classes.root}>
          <Grid container direction="row" spacing={0} className={classes.container}>
            <Grid item className={classes.gridItem}>
              <Typography className={classes.title} variant={'h6'}>
                {t('List of categories')}
              </Typography>
            </Grid>
            <Grid item className={`${classes.gridItem} ${classes.searchContainer}`}>
              <SearchProductCategoryFormContainer searchString={this.props.searchString} />
              <Button
                startIcon={<AddIcon />}
                variant={'contained'}
                className={classes.addCategoryButton}
                onClick={() => this.props.history.push('/products/categories/add')}
              >
                {t('Add')}
              </Button>
            </Grid>
          </Grid>
          {isLoading ? (
            <Spinner />
          ) : (
            <ProductCategoriesTable
              searchString={searchString}
              activePage={currentPage}
              handlePageChange={this.handlePageChange}
              categories={productCategories}
              productCategoriesCount={productCategoriesCount}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), withStyles(styles), withRouter)(ProductCategoriesList);
