import React from 'react';
import { TableRow, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import commonStyles from '../../helpers/commonStyles';
import { toLongDateTime } from '../../helpers/dateFormatter';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  headerRow: {
    backgroundColor: theme.palette.whiteblue,
  },
  tableContent: {
    backgroundColor: theme.palette.white,
  },
  tableHeader: {
    borderTop: commonStyles.tableHeaderTopBorder,
  },
  orderItemsBorder: {
    '& > *': {
      borderBottom: '0',
    },
  },
  orderItemsTitle: {
    color: theme.palette.primary.dark,
  },
});

const OrdersHistoryTableRow = (props) => {
  const { classes, order } = props;
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow key={order.id} className={classes.tableContent}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {order.number}
        </TableCell>
        <TableCell align="right">{toLongDateTime(order.date)}</TableCell>
        <TableCell align="right">
          {order.fridge.number}, {order.fridge.name}, {order.fridge.address}
        </TableCell>
        <TableCell align="right">{order.numberOfUnits}</TableCell>
        <TableCell align="right">{order.totalPaid}</TableCell>
        <TableCell align="right">{order.bonusesUsed}</TableCell>
        <TableCell align="right">{order.originalTotalPrice}</TableCell>
        <TableCell align="right">{order.totalProductionCost}</TableCell>
        <TableCell align="right">{order.status}</TableCell>
        <TableCell align="right">
          {order.user.firstName} {order.user.lastName}
        </TableCell>
        <TableCell align="right">{order.user.phoneNumber}</TableCell>
        <TableCell align="right">{toLongDateTime(order.user.registrationDate)}</TableCell>
      </TableRow>
      <TableRow className={!open ? classes.orderItemsBorder : ''}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={14}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography className={classes.orderItemsTitle} variant="h6" gutterBottom component="div">
                {t('Products')}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow className={classes.headerRow}>
                    <TableCell colSpan={2}>{t('Product name')}</TableCell>
                    <TableCell colSpan={2}>{t('Product category')}</TableCell>
                    <TableCell colSpan={2} align="right">
                      {t('Units')}
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      {t('Paid for unit')}
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      {t('Paid with bonuses')}
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      {t('Unit total price')}
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      {t('Unit production cost')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.orderItems.map((orderItem) => (
                    <TableRow key={orderItem.productName}>
                      <TableCell colSpan={2} component="th" scope="row">
                        {orderItem.productName}
                      </TableCell>
                      <TableCell colSpan={2} component="th" scope="row">
                        {orderItem.productCategory}
                      </TableCell>
                      <TableCell colSpan={2} align="right">
                        {orderItem.numberOfUnits}
                      </TableCell>
                      <TableCell colSpan={2} align="right">
                        {orderItem.paidForUnit}
                      </TableCell>
                      <TableCell colSpan={2} align="right">
                        {orderItem.bonusesUsed}
                      </TableCell>
                      <TableCell colSpan={2} align="right">
                        {orderItem.originalUnitPrice}
                      </TableCell>
                      <TableCell colSpan={2} align="right">
                        {orderItem.unitProductionCost}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default compose(withRouter, withStyles(styles))(OrdersHistoryTableRow);
