import React from 'react';
import { Field } from 'redux-form';
import InnerSelectField from './InnerSelectField';
import orderStatusConstants from '../../helpers/orderStatusConstants';
import { withTranslation } from 'react-i18next';

const OrderStatusSelectField = ({ t, ...props }) => {
  return (
    <Field
      name={props.name}
      options={Object.values(orderStatusConstants).map((orderStatus) => {
        return { value: orderStatus, label: orderStatus };
      })}
      component={InnerSelectField}
      getOptionLabel={(option) => t(option.label)}
      isSearchable={false}
      isClearable={true}
      {...props}
    />
  );
};

export default withTranslation()(OrderStatusSelectField);
