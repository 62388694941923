import React from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '../../common/Tables/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Pagination from '@material-ui/lab/Pagination';
import { TableRow, Typography, withStyles } from '@material-ui/core';
import { Block } from '@material-ui/icons';

import commonStyles from '../../helpers/commonStyles';
import paginationConstants from '../../helpers/paginationConstants';
import { toLongDateTime } from '../../helpers/dateFormatter';
import Menu from '../../fields/Menu';

const styles = (theme) => ({
  table: {
    minWidth: '1564px',
  },
  headerRow: {
    backgroundColor: theme.palette.whiteblue,
  },
  tableContent: {
    backgroundColor: theme.palette.white,
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: '1564px',
  },
  tableHeader: {
    borderTop: commonStyles.tableHeaderTopBorder,
  },
});

const UsersTable = (props) => {
  const {
    classes,
    users,
    handlePageChange,
    activePage,
    usersCount,
    toggleSortOrder,
    currentSortOrder,
    searchString,
    selectedFridgesIds,
    history,
    t,
    banUser,
    unbanUser,
  } = props;
  const pagesCount = Math.ceil(usersCount / paginationConstants.itemsPerPage);

  const viewUserOrders = (userId) => {
    history.push(`/orders?userId=${userId}`);
  };

  const toggleUserBan = (user) => {
    if (user.isBanned) {
      unbanUser(user.id);
    } else {
      banUser(user.id);
    }
  };

  return (
    <React.Fragment>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell
              name="fullName"
              className={classes.tableHeader}
              align="left"
              isSortable
              onChangeSort={toggleSortOrder}
              currentSort={currentSortOrder}
            >
              {t('Full name')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Phone number')}
            </TableCell>
            <TableCell
              name="registrationDate"
              className={classes.tableHeader}
              align="right"
              isSortable
              onChangeSort={toggleSortOrder}
              currentSort={currentSortOrder}
            >
              {t('Registration date')}
            </TableCell>
            <TableCell
              name="ordersCount"
              className={classes.tableHeader}
              align="right"
              isSortable
              onChangeSort={toggleSortOrder}
              currentSort={currentSortOrder}
            >
              {t('Number of orders')}
            </TableCell>
            <TableCell
              name="paymentBonuses"
              className={classes.tableHeader}
              isSortable
              align="right"
              onChangeSort={toggleSortOrder}
              currentSort={currentSortOrder}
            >
              {t('Number of bonuses')}
            </TableCell>
            <TableCell
              name="lastOrderDate"
              className={classes.tableHeader}
              align="right"
              isSortable
              onChangeSort={toggleSortOrder}
              currentSort={currentSortOrder}
            >
              {t('Last order date')}
            </TableCell>
            <TableCell name="isBanned" className={classes.tableHeader} align="right">
              {t('Is banned')}
            </TableCell>
            <TableCell className={classes.tableHeader} />
          </TableRow>
        </TableHead>
        <TableBody>
          {users.length !== 0
            ? users.map((user) => (
                <TableRow key={user.id} className={classes.tableContent}>
                  <TableCell component="th" scope="row">{`${user.firstName} ${user.lastName}`}</TableCell>
                  <TableCell align="right">{user.phoneNumber}</TableCell>
                  <TableCell align="right">{toLongDateTime(user.registrationDate)}</TableCell>
                  <TableCell align="right">{user.numberOfOrders}</TableCell>
                  <TableCell align="right">{user.paymentBonuses}</TableCell>
                  <TableCell align="right">{user.lastOrderDate && toLongDateTime(user.lastOrderDate)}</TableCell>
                  <TableCell align="center">{user.isBanned && <Block color="error" />}</TableCell>
                  <TableCell align="right">
                    <Menu
                      withArrow
                      placement="bottom-end"
                      options={[
                        {
                          key: 'viewUserOrders',
                          label: t('Orders'),
                          handleClick: () => viewUserOrders(user.id),
                        },
                        {
                          key: 'toggleBan',
                          label: user.isBanned ? t('Unban') : t('Ban'),
                          handleClick: () => toggleUserBan(user),
                        },
                      ]}
                    >
                      <Typography variant="subtitle2" className={classes.userInfo}>
                        {t('Action')}
                      </Typography>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))
            : (searchString || selectedFridgesIds) && (
                <TableRow className={classes.tableContent}>
                  <TableCell align={'center'} colSpan={6}>
                    {t('Could not find any user for your request.')}
                  </TableCell>
                </TableRow>
              )}
        </TableBody>
      </Table>
      <Pagination
        className={classes.paginationContainer}
        size={'large'}
        variant="outlined"
        shape="rounded"
        count={pagesCount}
        page={activePage}
        onChange={handlePageChange}
        color="primary"
      />
    </React.Fragment>
  );
};

export default compose(withTranslation(), withRouter, withStyles(styles))(UsersTable);
