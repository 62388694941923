import React from 'react';
import Spinner from '../../common/ProgressIndicators/Spinner';
import AddUpdateProductCategoryForm from '../../common/Forms/AddUpdateProductCategoryForm';
import { withTranslation } from 'react-i18next';

class UpdateProductCategory extends React.Component {
  componentDidMount() {
    this.props.getProductCategoryById(this.props.categoryId, this.props.history);
  }

  render() {
    const { isLoading, t } = this.props;

    return isLoading ? <Spinner /> : <AddUpdateProductCategoryForm pageTitle={t('Category editing')} {...this.props} />;
  }
}

export default withTranslation()(UpdateProductCategory);
