import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import * as accountActions from '../../../redux/modules/account/accountActions';
import { validateChangePasswordForm } from '../../validators/formValidators';
import ChangePasswordForm from './ChangePasswordForm';
import { SubmissionError } from 'redux-form';
import { showNotification } from '../../helpers/notificationHelper';
import notificationTypes from '../../helpers/notificationTypes';
import i18n from 'i18next';

const selector = formValueSelector('changePasswordForm');

const mapStateToProps = (state) => ({
  oldPassword: selector(state, 'oldPassword'),
  newPassword: selector(state, 'newPassword'),
  confirmNewPassword: selector(state, 'confirmNewPassword'),
  isLoading: state.account.isLoading,
});

const mapDispatchToProps = {
  changePassword: accountActions.changePassword,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'changePasswordForm',
    validate: validateChangePasswordForm,
    onSubmit: (payload, dispatch, props) => {
      return props
        .changePassword(payload)
        .then(() => {
          props.history.push('/fridges');
          showNotification(notificationTypes.success, i18n.t('Password was successfully changed.'));
        })
        .catch((error) => {
          throw new SubmissionError({ _error: error });
        });
    },
  }),
)(ChangePasswordForm);
