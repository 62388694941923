import React, { useState, useEffect } from 'react';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { createTheme } from '@material-ui/core';
import { globalThemeObject } from '../styles/GlobalThemeObject';
import dateFormats from '../helpers/dateFormats';
import moment from 'moment';
import 'moment/locale/ru';
import { useTranslation } from 'react-i18next';

const datePickerTheme = createTheme({
  typography: globalThemeObject.typography,
  palette: globalThemeObject.palette,
  border: globalThemeObject.border,
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: globalThemeObject.palette.primary.dark,
        '&:hover': {
          backgroundColor: globalThemeObject.palette.secondary.dark,
        },
      },
      current: {
        color: globalThemeObject.palette.primary.dark,
      },
    },
    MuiOutlinedInput: {
      input: {
        cursor: 'pointer',
        padding: '8px, 6px, 7px, 0',
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '1rem',
        margin: '6px 0 0 8px',
        color: globalThemeObject.select.lightblack,
      },
    },
  },
});

const DateRangePicker = ({ onChange, start, end }) => {
  const [selectedStartDate, setStartDate] = useState(start ? start : moment().add(-1, 'd'));
  const [selectedEndDate, setEndDate] = useState(end ? end : moment());
  const { t } = useTranslation();

  useEffect(() => {
    onChange(selectedStartDate, selectedEndDate);
  });

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={createTheme(datePickerTheme)}>
        <DatePicker
          autoOk
          label={t('Beginning')}
          format={dateFormats.shortDateTime}
          variant="inline"
          inputVariant="outlined"
          value={selectedStartDate}
          maxDate={moment(selectedEndDate)}
          onChange={setStartDate}
        />

        <DatePicker
          autoOk
          label={t('End')}
          format={dateFormats.shortDateTime}
          variant="inline"
          inputVariant="outlined"
          value={selectedEndDate}
          minDate={moment(selectedStartDate)}
          onChange={setEndDate}
          style={{ marginLeft: '10px' }}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default DateRangePicker;
