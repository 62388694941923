import { connect } from 'react-redux';
import * as productsActions from './../../../redux/modules/products/productsActions';
import Import from './Import';

const mapStateToProps = (state) => {
  return {
    isLoading: state.products.isLoading,
  };
};

const mapDispatchToProps = {
  importImages: productsActions.importImages,
  importProducts: productsActions.importProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Import);
