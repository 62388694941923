import SELLERS_CONSTANTS from './sellersConstants';
import axios from 'axios';

export const getSellerBasicInfo = (sellerId) => (dispatch) => {
  dispatch({
    type: SELLERS_CONSTANTS.SELLERS_PENDING,
    payload: true,
  });
  return axios.get(`/sellers/${sellerId}`).then((response) =>
    dispatch({
      type: SELLERS_CONSTANTS.GET_SELLER_BASIC_INFO,
      payload: response.data,
    }),
  );
};
