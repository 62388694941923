import React, { useEffect } from 'react';
import { TextField, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Spinner from '../../common/ProgressIndicators/Spinner';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  button: {
    fontSize: '18px',
    padding: '4px 16px',
    width: '60%',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
  },
  title: {
    color: theme.palette.primary.dark,
  },
  childElement: {
    width: '100%',
    marginLeft: '50px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  flexContainer: {
    width: '650px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
  },
  input: {
    border: 'none',
    width: '600px',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '2% 5% 1% 5%',
    fontFamily: theme.typography.fontFamily,
  },
  image: {
    maxWidth: '600px',
  },
});

const ProductDetails = (props) => {
  const { product, classes, isLoading, productId, history, getProduct, t } = props;

  const editProduct = (productId) => {
    history.push(`/products/${productId}/edit`);
  };

  useEffect(() => {
    getProduct(productId, history);
  }, [getProduct, history, productId]);

  return isLoading ? (
    <Spinner />
  ) : (
    <div className={classes.flexContainer}>
      <div className={classes.childElement}>
        <Typography className={classes.title} color={'primary'} variant={'h5'}>
          {t('Product details')}
        </Typography>
      </div>
      <TextField
        value={`${product.barcodeValue}`}
        inputProps={{ readOnly: true }}
        className={classes.input}
        label={t('Product barcode')}
        variant="outlined"
      />
      <TextField
        value={`${product.name}`}
        className={classes.input}
        inputProps={{ readOnly: true }}
        label={t('Product name')}
        variant="outlined"
      />
      <TextField
        value={`${product.price}`}
        className={classes.input}
        inputProps={{ readOnly: true }}
        label={t('Product price')}
        variant="outlined"
      />
      <TextField
        value={`${product.productionCost || ''}`}
        className={classes.input}
        inputProps={{ readOnly: true }}
        label={t('Product production cost')}
        variant="outlined"
      />
      <TextField
        value={`${product.proteins}`}
        className={classes.input}
        inputProps={{ readOnly: true }}
        label={t('Amount of proteins in the product')}
        variant="outlined"
      />
      <TextField
        value={`${product.fats}`}
        className={classes.input}
        inputProps={{ readOnly: true }}
        label={t('Amount of fats in the product')}
        variant="outlined"
      />
      <TextField
        value={`${product.carbohydrates}`}
        className={classes.input}
        inputProps={{ readOnly: true }}
        label={t('Amount of carbohydrates in the product')}
        variant="outlined"
      />
      <TextField
        value={`${product.kiloCalories}`}
        className={classes.input}
        inputProps={{ readOnly: true }}
        label={t('Product caloric content')}
        variant="outlined"
      />
      <TextField
        multiline={true}
        rows={1}
        rowsMax={4}
        value={`${product.ingredients}`}
        className={classes.input}
        inputProps={{ readOnly: true }}
        label={t('Product ingredients')}
        variant="outlined"
      />
      <TextField
        multiline={true}
        rows={1}
        rowsMax={6}
        value={`${product.notes || ''}`}
        className={classes.input}
        inputProps={{ readOnly: true }}
        label={t('Product notes')}
        variant="outlined"
      />
      <TextField
        value={`${product.categoryName}`}
        className={classes.input}
        inputProps={{ readOnly: true }}
        label={t('Product category')}
        variant="outlined"
      />
      <div className={classes.childElement}>
        <Typography className={classes.title} color={'primary'} variant={'subtitle2'}>
          {t('Product image:')}
        </Typography>
        {product.imageUrl && <img className={classes.image} src={product.imageUrl} alt="" />}
      </div>
      <div className={classes.childElement}>
        <Button onClick={() => editProduct(product.id)} variant="contained" type="submit" className={classes.button}>
          {t('Edit')}
        </Button>
      </div>
    </div>
  );
};

export default compose(withTranslation(), withStyles(styles), withRouter)(ProductDetails);
