import ACCOUNT_CONSTANTS from './accountConstants';

const defaultState = {
  isLoading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ACCOUNT_CONSTANTS.ACCOUNT_PENDING:
      return handleRequestPending(state, action.payload);

    case ACCOUNT_CONSTANTS.CHANGEPASSWORD_SUCCEEDED:
      return handlePasswordChanged(state, action.payload.accessToken, action.payload.currentUser);

    default:
      return state;
  }
};

function handleRequestPending(state, pending) {
  return {
    ...state,
    isLoading: pending,
  };
}

function handlePasswordChanged(state) {
  return {
    ...state,
    isLoading: false,
  };
}
