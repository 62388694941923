import { compose } from 'redux';
import { withRouter } from 'react-router';
import { getPageNumber } from '../../helpers/queryHelper';
import { connect } from 'react-redux';
import UnlockHistory from './UnlockHistory';
import * as fridgesActions from './../../../redux/modules/fridges/fridgesActions';
import { getQueryParamFromUrl } from '../../helpers/getParamsFromUrl';

const mapStateToProps = (state, props) => {
  return {
    isLoading: state.fridges.unlockHistory.isLoading,
    unlockRecordsCount: state.fridges.unlockHistory.unlockRecordsCount,
    currentPage: getPageNumber(props),
    unlockHistory: state.fridges.unlockHistory.items,
    fridgeId: getQueryParamFromUrl(props, 'fridgeId') || '',
    onlySuspiciousUnlocks: getQueryParamFromUrl(props, 'onlySuspiciousUnlocks') || false,
  };
};

const mapDispatchToProps = {
  getFridgeUnlockHistory: fridgesActions.getFridgeUnlockHistory,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(UnlockHistory);
