import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import FilterProductsRatingForm from './FilterProductsRatingForm';
import { toShortDateTime } from '../../../helpers/dateFormatter';
import { dateRangeOptions } from '../../../fields/Select/DateRangeSelectField';
import { getQueryString } from '../../../helpers/queryHelper';

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    dateRange: dateRangeOptions.find(
      (o) =>
        toShortDateTime(o.value.endDate) === ownProps.endDate &&
        toShortDateTime(o.value.startDate) === ownProps.startDate,
    ),
    selectedFridges: state.fridges.fridges
      .filter((f) => ownProps.fridgesIds.includes(f.id))
      .map((f) => ({ value: f.id, label: `#${f.number}, ${f.name}` })),
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  reduxForm({
    form: 'filterProductsRatingForm',
    onSubmit: (payload, dispatch, props) => {
      let startDate;
      let endDate;
      if (payload.dateRange) {
        startDate = toShortDateTime(payload.dateRange.value.startDate);
        endDate = toShortDateTime(payload.dateRange.value.endDate);
      }

      const queryString = getQueryString({
        page: 1,
        startDate: startDate,
        endDate: endDate,
        fridges: payload.selectedFridges,
      });
      props.history.push({
        pathname: props.history.location.pathname,
        search: queryString,
      });
    },
    enableReinitialize: true,
  }),
)(FilterProductsRatingForm);
