import React, { useEffect } from 'react';
import Spinner from '../../common/ProgressIndicators/Spinner';
import AddUpdateProductForm from '../../common/Forms/AddUpdateProductForm';
import { useTranslation } from 'react-i18next';

const UpdateProductForm = (props) => {
  const { isLoading } = props;
  const { t } = useTranslation();

  useEffect(() => {
    props.getProduct(props.productId, props.history);
    props.getProductCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? <Spinner /> : <AddUpdateProductForm pageTitle={t('Product editing')} {...props} />;
};

export default UpdateProductForm;
